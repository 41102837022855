import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApplicationApi } from "./services/web-api/application-api";
import { ApplicationRestApi } from "./services/web-api/rest/application-rest-api";
import Login from "./components/Login";
import Layout from "./components/Layout";
import {
  ApplicationContext,
  ApplicationContextProps,
} from "./context/ApplicationContext";
import AuthorizedRoute from "./components/routing/AuthorizedRoute";

import "./App.css";
import ChangePassword from "./components/ChangePassword";
import Dashboard from "./components/Dashboard";
import Users from "./components/Users";
import Integration from "./components/Integration";
import AlectooApplication from "./components/AlectooApplication";
import Presence from "./components/Presence";
import PhoneDashboard from "./components/PhoneDashboard";
import PhoneNumbers from "./components/PhoneNumbers";
import PhoneRange from "./components/PhoneRange";
import License from "./components/License";
import Customers from "./components/Customers";
import MFAuthentication from "./components/MFAuthentication";
import GroupUsers from "./components/GroupUsers";
import GroupProviders from "./components/GroupProviders";
import GroupApplication from "./components/GroupApplication";
import Groups from "./components/Groups";
import Monitoring from "./components/MonitorMail";
import { useState } from "react";
import SamwinManager from "./components/SamwinManager";
import SamwinRecording from "./components/SamwinRecording";
import VoiceMessages from "./components/VoiceMessages";
import Faq from "./components/FAQ";
import Manuals from "./components/Manuals";
import LoginJansson from "./components/LoginJansson";
import Phrases from "./components/Phrases";
import Messages from "./components/Messages";
import AdhocGroups from "./components/AdhocGroups";
import AdhocMobileGroups from "./components/MobileGroups";
import AdhocMobileUsers from "./components/MobileUsers";
import "./theme.scss";

const webApi = new ApplicationApi<ApplicationRestApi>(
  new ApplicationRestApi(process.env.REACT_APP_API_URL ?? "")
);

const applicationContext: ApplicationContextProps = {
  webApi: webApi,
  customerId: -1,
  setCustomerId: () => {},
  theme: 1,
};

function App() {
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.webApi?.getCredentials()?.customer ?? -1
  );
  applicationContext.customerId = customerId;
  applicationContext.setCustomerId = setCustomerId;
  applicationContext.theme = window.location.hostname.includes("jkportal")
    ? 2
    : 1;

  if (applicationContext.theme === 2) {
    document.getElementById("root")!.setAttribute("data-bs-theme", "jansson");
  }

  return (
    <BrowserRouter>
      <ApplicationContext.Provider value={applicationContext}>
        <Routes>
          {applicationContext.theme === 1 && (
            <Route path="/login" element={<Login />} />
          )}
          {applicationContext.theme === 2 && (
            <Route path="/login" element={<LoginJansson />} />
          )}
          <Route path="/password" element={<ChangePassword />} />
          <Route element={<AuthorizedRoute />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/integration" element={<Integration />} />
              <Route path="/alectooapp" element={<AlectooApplication />} />
              <Route path="/presence" element={<Presence />} />
              <Route path="/phonedashboard" element={<PhoneDashboard />} />
              <Route path="/phonenumbers" element={<PhoneNumbers />} />
              <Route path="/phonerange" element={<PhoneRange />} />
              <Route path="/license" element={<License />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/mfauthentication" element={<MFAuthentication />} />
              <Route
                path="/alectooapplication"
                element={<AlectooApplication />}
              />
              <Route path="/groupusers" element={<GroupUsers />} />
              <Route path="/groupproviders" element={<GroupProviders />} />
              <Route path="/application" element={<GroupApplication />} />
              <Route path="/groups" element={<Groups />} />
              <Route path="/monitoring" element={<Monitoring />} />
              <Route path="/samwinmanager" element={<SamwinManager />} />
              <Route path="/voicemessages" element={<VoiceMessages />} />
              <Route path="/samwinrecording" element={<SamwinRecording />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/manuals" element={<Manuals />} />
              <Route path="/phrases" element={<Phrases />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/adhocgroups" element={<AdhocGroups />} />
              <Route path="/mobilegroups" element={<AdhocMobileGroups />} />
              <Route path="/mobileusers" element={<AdhocMobileUsers />} />
            </Route>
          </Route>
        </Routes>
      </ApplicationContext.Provider>
    </BrowserRouter>
  );
}

export default App;
