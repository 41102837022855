import React from "react";

export interface PaginationItemProps {
  className?: string;
  style?: React.CSSProperties;
  active?: boolean;
  children?: React.ReactNode;
}

const PaginationItem: React.FC<PaginationItemProps> = (
  props: PaginationItemProps
) => {
  let className = "page-item" + (props.className ? " " + props.className : "");

  if (props.active) {
    className = className + " active";
  }

  return (
    <li className={className} style={props.style}>
      {props.children}
    </li>
  );
};

export default PaginationItem;
