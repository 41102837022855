import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import Container from "./ui/Container";
import Navbar from "./ui/Navbar";
import DropDownToggle from "./ui/DropDownToggle";
import DropDown from "./ui/DropDown";
import DropDownMenu from "./ui/DropDownMenu";
import DropDownLink from "./ui/DropDownLink";
import DropDownDevider from "./ui/DropDownDivider";
import DropDownButton from "./ui/DropDownButton";
import CustomerViewBar from "./CustomerViewBar";
import { ReactComponent as UserIcon } from "./icons/UserIcon.svg";
import { ReactComponent as HamburgerMenu } from "./icons/HamburgerMenu.svg";
import { ApplicationContext } from "../context/ApplicationContext";
import SideBar from "./SideBar";
import "./AppNavBar.css";
import { ReactComponent as CloseX } from "./icons/closeX.svg";

const AppNavBar: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const [isSideBarVisible, setIsSideBarVisible] = useState(false);

  const logout = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    applicationContext.webApi?.logout();
    window.location.reload();
  };
  const currentUser = applicationContext.webApi?.getCredentials()?.name ?? "";

  const renderCustomerBarView = () => {
    return <CustomerViewBar />;
  };

  return (
    <>
      <Navbar
        className={`bg-primary${isSideBarVisible ? "fade-in" : ""}`}
        containerClassName="d-flex justify-content-lg-end align-items-center h-100"
        style={{ minHeight: "70px", transition: "1" }}
      >
        <div className="d-flex flex-row justify-content-between w-100">
          <div className="d-flex flex-row d-lg-none ">
            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setIsSideBarVisible(!isSideBarVisible)}
            >
              <HamburgerMenu
                fill="#DDE6ED"
                style={{ height: "30px", width: "30px" }}
              />
            </button>
          </div>

          <div className="d-flex flex-row justify-content-end w-100">
            {applicationContext.webApi?.isSuperAdmin()
              ? renderCustomerBarView()
              : null}
            <DropDown>
              <DropDownToggle className="text-white pe-0">
                <UserIcon
                  fill="#DDE6ED"
                  style={{ height: "24px", width: "24px" }}
                />
              </DropDownToggle>
              <DropDownMenu className="dropdown-menu-end">
                <DropDownLink linkClassName="disabled" to="#">
                  {currentUser ?? ""}
                </DropDownLink>
                <DropDownDevider />
                <DropDownLink to="/mfauthentication">
                  2F Authentication
                </DropDownLink>
                <DropDownDevider />
                <DropDownButton onClick={logout}>Logout</DropDownButton>
              </DropDownMenu>
            </DropDown>
          </div>
        </div>
      </Navbar>

      <div className={`overlay${isSideBarVisible ? " show" : ""}`}>
        <div className=" p-0 d-lg-none w-100">
          <SideBar />
        </div>
        <button
          className="btn btn-md button-close"
          type="button"
          onClick={() => {
            setIsSideBarVisible(!isSideBarVisible);
          }}
        >
          <CloseX className="closeX" fill="white" />
        </button>
      </div>

      <Container className="vh-100 w-100 rounded-top bg-body ps-4" fluid>
        <div style={{ height: "calc(100vh - 80px)", overflowY: "scroll" }}>
          <Outlet />
        </div>
      </Container>
    </>
  );
};

export default AppNavBar;
