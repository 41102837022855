import React from "react";

export interface DropDownButtonProps {
  className?: string;
  buttonClassName?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DropDownButton: React.FC<DropDownButtonProps> = (
  props: DropDownButtonProps
) => {
  let buttonClassName =
    "dropdown-item" +
    (props.buttonClassName ? " " + props.buttonClassName : "");
  return (
    <li className={props.className} style={props.style}>
      <button type="button" className={buttonClassName} onClick={props.onClick}>
        {props.children}
      </button>
    </li>
  );
};

export default DropDownButton;
