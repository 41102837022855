import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import { useNavigate } from "react-router-dom";
import { LoginResponse } from "../services/web-api/application-api";
import "./LoginJansson.css";
import { Desktop, TabletMobile } from "../utils/breakpoints";

type RememberMe = {
  email: string;
  isOn: boolean;
};
export type LoginState = "login" | "forgotpwd" | "authenticate";

const LoginJansson = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rememberme, setRememberme] = useState<boolean>(false);
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formError, setFormError] = useState<boolean>(false);

  const applicationContext = useContext(ApplicationContext);

  const handleOnRememberMeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRememberme(event.target.checked);
  };
  const [loginState, setLoginState] = useState("login" as LoginState);

  const navigate = useNavigate();

  useEffect(() => {
    const remembermeData = localStorage.getItem("rememberme");

    if (remembermeData) {
      const tempRememberMe: RememberMe = JSON.parse(remembermeData);
      setRememberme(tempRememberMe.isOn);
      if (tempRememberMe.isOn) {
        setInput({ ...input, username: tempRememberMe.email });
      }
    }
  }, [applicationContext.webApi, input]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    if (event.currentTarget.reportValidity()) {
      setIsLoading(true);

      localStorage.setItem(
        "rememberme",
        JSON.stringify({ email: input["username"], isOn: rememberme })
      );
      applicationContext.webApi
        ?.login(input["username"], input["password"], "", rememberme)
        .then((loginResponse: LoginResponse) => {
          applicationContext.setCustomerId(loginResponse.customer);
          navigate("/");
        })
        .catch((error) => {
          let errorMessage = "Unable to login! Error: " + error;

          if (error === 409) {
            navigate("/authentication", {
              state: {
                userName: input["username"],
                password: input["password"],
                rememberme: input["rememberme"],
              },
            });
          } else if (error === 403) {
            errorMessage = "Invalid username and/or password!";
          }

          setErrorMessage(errorMessage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setFormError(true);
    }
  };

  const renderLoginSpinner = () => {
    return (
      <button
        type="submit"
        className="btn btn-secondary justify-content-center w-100 text-dark fw-bold">
        {isLoading ? (
          <>
            <span
              className="spinner-border spinner-border-sm me-2 "
              aria-hidden="true"></span>
            Login
          </>
        ) : (
          "Login"
        )}
      </button>
    );
  };
  const renderLoginForm = () => {
    return (
      <>
        <div className="right-body bg-primary">
          <form
            className={
              "needs-validation " +
              (formError === true ? "was-validated p-5 mx-3" : " p-5 mx-3")
            }
            onSubmit={handleSubmit}
            noValidate>
            {errorMessage && (
              <div className="text-danger w-100">
                <ul>
                  <li>{errorMessage}</li>
                </ul>
              </div>
            )}
            <div className="d-flex justify-content-center p-4">
              {/* <label className="text-primary">Login to your account</label> */}
            </div>
            <label className="text-light pb-2">Email</label>
            <input
              type="email"
              className="form-control email mb-1"
              placeholder="Enter your email"
              name="username"
              onChange={handleOnChange}
              required
            />
            <span className="invalid-feedback mb-2">Email is required!</span>
            <p className="helper-text text-primary">
              {/* You must use valid email address! */}
            </p>
            <label className="text-light pb-2">Password</label>
            <input
              type="password"
              className="form-control password mb-1"
              placeholder="Password"
              name="password"
              onChange={handleOnChange}
              required
            />
            <span className="invalid-feedback mb-2">Password is required!</span>
            <p className="helper-text text-primary mb-2 pb-5">
              {/* Password must be at leat 6 characters long */}
            </p>
            <div className="d-flex justify-content-between"></div>
            <div className="justify-content-center align-items-center pb-3">
              {renderLoginSpinner()}
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center ">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mobile-rememberme"
                  name="rememberme"
                  onChange={handleOnRememberMeChange}
                  checked={rememberme}
                />
                <label
                  className="form-check-label text-light fw-light"
                  htmlFor="mobile-rememberme">
                  Remember me
                </label>
              </div>
              <div>
                <a
                  className="text-light underline fw-light "
                  role="button"
                  onClick={() => {
                    setLoginState("forgotpwd");
                  }}>
                  Forgot password?
                </a>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  };
  return (
    <>
      <Desktop>
        <div className="d-flex justify-content-center align-items-center w-100 h-100 position-fixed">
          <div className="d-flex m-auto">
            <div className="container d-flex flex-row justify-content-center">
              <div
                className="d-flex justify-content-center align-items-center bg-lightgray left-body "
                // style={{ width: "400px" }}
              >
                <img
                  className="logo-img "
                  src="/images/Jansson-logo.png"
                  alt="PANTER LOGO"
                  style={{ width: "200px", height: "200px" }}
                />
              </div>
              {renderLoginForm()}
            </div>
          </div>
        </div>
      </Desktop>
      <TabletMobile>
        <div className="d-flex justify-content-center align-items-center w-100 h-100 position-fixed">
          <div className="d-flex m-auto w-100">
            <div className="container d-flex flex-row justify-content-center ">
              {loginState === "login" && renderLoginForm()}
            </div>
          </div>
        </div>
      </TabletMobile>
    </>
  );
};

export default LoginJansson;
