import React, {
  useState,
  useContext,
  ReactElement,
  useEffect,
  useCallback,
} from "react";
import CustomerBar from "./CustomerBar";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import CardFooter from "./ui/CardFooter";
import Table, { TableData, TableInfo } from "./ui/Table";
import { ApplicationContext } from "../context/ApplicationContext";
import "./Users.css";

const Users: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.webApi?.isSuperAdmin()
      ? applicationContext.customerId
      : applicationContext.webApi?.getCredentials()?.customer ?? -1
  );
  const [addCollapse, setAddCollapse] = useState(false);
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [role, setRole] = useState(2);
  const [roleAdd, setRoleAdd] = useState(2);
  const [frmError, setFrmError] = useState(false);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadDataForCustomer = useCallback(
    (customerId: number, currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getUsers(customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setTableInfo({ currentPage: currentPage, step: step, search: search });
      loadDataForCustomer(customerId, currentPage, step, search);
    },
    [customerId, loadDataForCustomer]
  );

  useEffect(() => {
    loadDataForCustomer(customerId, 1, tableInfo.step, tableInfo.search);
  }, [customerId, applicationContext.webApi]);

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    clearSearch();
  }, [applicationContext.customerId]);

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    clearSearch();
  };

  const submitEditHandler = (
    event: React.FormEvent<HTMLFormElement>,
    index: number
  ) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      if (index >= 0) {
        handleEditOnSave(index);
        setFrmError(false);
      } else {
        handleAdd();
      }
    } else {
      setFrmError(true);
    }
  };

  const handleAdd = () => {
    const firstName = input["firstNameAdd"];
    const lastName = input["lastNameAdd"];
    const userName = input["userNameAdd"];
    const password = input["passwordAdd"];
    const email = input["emailAdd"];

    applicationContext.webApi
      ?.createUser({
        id: 0,
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        password: password,
        email: email,
        roleId: roleAdd,
        customerId: customerId,
      })
      .then(() => {
        handleAddClose();
        setTimeout(() => {
          loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
        }, 500);
      });
  };

  const handleEditOnSave = (index: number) => {
    const firstName = input["firstName"];
    const lastName = input["lastName"];
    const userName = input["userName"];
    const password = input["password"];
    const email = input["email"];

    applicationContext.webApi
      ?.saveUser({
        id: data.rows[index].id,
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        password: password,
        email: email,
        roleId: role,
        customerId: customerId,
      })
      .then(() => {
        setTimeout(() => {
          loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
        }, 500);
      });
  };

  const renderForm = (index: number) => {
    return (
      <form
        className={"needs-validation p-1" + (frmError ? " was-validated" : "")}
        onSubmit={(event) => {
          submitEditHandler(event, index);
        }}
        noValidate
      >
        <Card className="bg-secondary bg-opacity-25 m-2">
          <CardBody>
            {/* 1 */}
            <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
                <label
                  className="form-label text-primary mb-0 ps-md-2 user-label-size"
                  htmlFor={`firstName-${index}`}
                  id={`firstName-label-${index}`}
                >
                  First Name:
                </label>
                <input
                  className="form-control form-control-sm text-primary"
                  name={index >= 0 ? "firstName" : "firstNameAdd"}
                  id={`firstName-${index}`}
                  value={
                    index >= 0
                      ? input["firstName"] || ""
                      : input["firstNameAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                  required
                />
                <div className="invalid-tooltip">First Name is required!</div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center pe-2 w-100">
                <label
                  className="form-label text-primary ps-md-3 mb-0 user-label-size"
                  htmlFor={`lastName-${index}`}
                  id={`lastName-label-${index}`}
                >
                  Last Name:
                </label>
                <input
                  className="form-control form-control-sm text-primary"
                  name={index >= 0 ? "lastName" : "lastNameAdd"}
                  id={`lastName-${index}`}
                  value={
                    index >= 0
                      ? input["lastName"] || ""
                      : input["lastNameAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                />
              </div>
              {/* 2 */}
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
                <label
                  className="form-label text-primary ps-md-2 user-label-size"
                  htmlFor={`userName-${index}`}
                  id={`userName-label-${index}`}
                >
                  Username:
                </label>
                <input
                  className="form-control form-control-sm text-primary"
                  type="email"
                  name={index >= 0 ? "userName" : "userNameAdd"}
                  id={`userName-${index}`}
                  value={
                    index >= 0
                      ? input["userName"] || ""
                      : input["userNameAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                  required
                />
                <div className="invalid-tooltip">Username is required!</div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="form-label text-primary ps-md-3 mb-0 user-label-size"
                  htmlFor={`password-${index}`}
                  id={`password-label-${index}`}
                >
                  Password:
                </label>
                <input
                  className="form-control form-control-sm text-primary"
                  type="password"
                  name={index >= 0 ? "password" : "passwordAdd"}
                  id={`password-${index}`}
                  value={
                    index >= 0
                      ? input["password"] || ""
                      : input["passwordAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                  required={index < 0}
                />
                <div className="invalid-tooltip">Password is required!</div>
              </div>
            </div>
            {/* 3 */}
            <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
                <label
                  className="form-label text-primary mb-0 ps-md-2 user-label-size"
                  htmlFor={`email-${index}`}
                  id={`email-label-${index}`}
                >
                  Email:
                </label>
                <input
                  type="email"
                  name={index >= 0 ? "email" : "emailAdd"}
                  value={
                    index >= 0 ? input["email"] || "" : input["emailAdd"] || ""
                  }
                  id={`email-${index}`}
                  className="form-control form-control-sm text-primary"
                  disabled={index >= 0}
                  onChange={handleInputOnChange}
                  required
                />
                <div className="invalid-tooltip">E-mail is required!</div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="form-label text-primary ps-md-3 mb-0 user-label-size"
                  htmlFor={`role-${index}`}
                >
                  Role:
                </label>
                <select
                  id={`role-${index}`}
                  className="form-select form-select-sm text-primary"
                  value={index >= 0 ? role : roleAdd}
                  onChange={(event) =>
                    index >= 0
                      ? setRole(parseInt(event.target.value, 10))
                      : setRoleAdd(parseInt(event.target.value, 10))
                  }
                  disabled={applicationContext.webApi?.isUser()}
                >
                  <option className="text-primary" key="role-admin" value="1">
                    Admin
                  </option>
                  <option className="text-primary" key="role-user" value="2">
                    User
                  </option>
                </select>
              </div>
            </div>
          </CardBody>
          <CardFooter className="bg-secondary bg-opacity-25">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>
                <div
                  className={`${deleteConfirm && index >= 0 ? "" : "d-none"}`}
                >
                  <span className="text-primary me-2">
                    Are you sure you want to delete the User?
                  </span>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary me-3"
                    onClick={() => {
                      handleDeleteYes(index);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleDeleteNo}
                  >
                    No
                  </button>
                </div>
                <div style={deleteConfirm ? { display: "none" } : {}}>
                  <button
                    type="button"
                    className={
                      "btn btn-sm btn-danger customer-edit-button" +
                      (index >= 0 ? "" : " d-none")
                    }
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary user-edit-button m-1"
                  onClick={() => {
                    index >= 0 ? handleClose(index) : handleAddClose();
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-primary user-edit-button m-1"
                >
                  Save
                </button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </form>
    );
  };

  const handleDeleteYes = (index: number) => {
    const userId = data.rows[index].id;
    index >= 0 ? handleClose(index) : handleAddClose();
    applicationContext.webApi?.deleteUser(userId).then(() => {
      setTimeout(() => {
        loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
      }, 500);

      setDeleteConfirm(false);
    });
  };

  const handleDeleteNo = () => {
    setDeleteConfirm(false);
  };

  const handleDelete = () => {
    setDeleteConfirm(true);
  };

  const handleClose = (index: number) => {
    let tempData = Object.assign({}, data);
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const handleAddClose = () => {
    setAddCollapse(!addCollapse);
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Users" onCustomerChange={handleCustomerChange} />
      </div>
      <div>
        <Table
          className="table table-sm"
          data={data}
          tableInfo={tableInfo}
          setTableInfo={setTableInfo}
          responsive
          bordered
          loadData={loadData}
          isLoading={isLoading}
          addCollapse={addCollapse}
          toggleAddCollapse={(): void => {
            let inputData = Object.assign({}, input);
            inputData["firstNameAdd"] = "";
            inputData["lastNameAdd"] = "";
            inputData["userNameAdd"] = "";
            inputData["passwordAdd"] = "";
            inputData["emailAdd"] = "";
            setRoleAdd(2);

            setAddCollapse(!addCollapse);
            setInput(inputData);
          }}
          toggleEditCollapse={(index: number): void => {
            let tempData = Object.assign({}, data);
            let inputData = Object.assign({}, input);
            tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;

            inputData["firstName"] = tempData.rows[index].firstName;
            inputData["lastName"] = tempData.rows[index].lastName;
            inputData["userName"] = tempData.rows[index].userName;
            inputData["password"] = "";
            inputData["email"] = tempData.rows[index].email;
            inputData["role"] = tempData.rows[index].roleId;
            setRole(tempData.rows[index].roleId);
            setInput(inputData);
            setData(tempData);
          }}
          renderAddCollapse={(): ReactElement => {
            return renderForm(-1);
          }}
          renderEditCollapse={(index: number): ReactElement => {
            return renderForm(index);
          }}
        />
      </div>
    </>
  );
};

export default Users;
