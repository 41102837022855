import React, { useState, useContext, useEffect, useCallback, ReactElement } from "react";
import CustomerBar from "./CustomerBar";
import Table, { TableData, TableInfo } from "./ui/Table";
import { ApplicationContext } from "../context/ApplicationContext";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import CardFooter from "./ui/CardFooter";

type Option = {
  label: string;
  value: string;
};

const Presence: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
 // const [input, setInput] = useState<{ [key: string]: string }>({});
  const [frmError, setFrmError] = useState(false);
  const [mobileAvailability, setMobileAvailability] = useState<string>("Available");
  const [mobileActivity, setMobileActivity] = useState<string>("Available");
  const [mobilePresence, setMobilePresence] = useState<string>("Available");
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );

  const firstOption: Option[] = [
    { label: "Available", value: "1" },
    { label: "Busy", value: "2" },
    { label: "Offline", value: "0" },
  ];

  const secondOption: Option[] = [
    { label: "Available", value: "Available" },
    { label: "Busy", value: "Busy" },
    { label: "Away", value: "Away" },
    { label: "DoNotDisturb", value: "DoNotDisturb" },
  ];

  const thirdOption: { [key: string]: Option[] } = {
    Available: [{ label: "Available", value: "Available" }],
    Busy: [
      { label: "InACall", value: "InACall" },
      { label: "InAConferenceCall", value: "InAConferenceCall" },
    ],
    DoNotDisturb: [{ label: "Presenting", value: "Presenting" }],
    Away: [{ label: "Away", value: "Away" }],
  };


  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadDataForCustomer = useCallback(
    (customerId: number, currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getPresence(customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  useEffect(() => {
    loadDataForCustomer(customerId, 1, tableInfo.step, tableInfo.search);
  
  }, [customerId]);

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    clearSearch();
  }, [applicationContext.customerId]);

  useEffect(() => {
    if (mobileAvailability) {
      const thirdOptions = thirdOption[mobileAvailability];
      if (thirdOptions && thirdOptions.length > 0) {
        setMobileActivity(thirdOptions[0].value);
      }
    }
  }, [mobileAvailability]);

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setTableInfo({ currentPage: currentPage, step: step, search: search });
      loadDataForCustomer(customerId, currentPage, step, search);
    },
    [customerId, loadDataForCustomer]
  );

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    clearSearch();
  };

  const handleClose = (index: number) => {
    let tempData = { ...data };
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const rowCustomRender = (i: number, j: number) => {
    let row = data.rows[i];
    let column = data.columns[j];

    if (column.field === "state") {
      if (row[column.field] === 1) {
        return "Available";
      } else if (row[column.field] === 2) {
        return "Busy";
      } else {
        return "Offline";
      }
    } else if (column.field === "showPresence") {
      return row[column.field] === true ? "Yes" : "No";
    } else if (column.field === "showTeams") {
      return row[column.field] === true ? "Yes" : "No";
    } else {
      return row[column.field] === null ? "" : row[column.field].toString();
    }
  };

  const submitEditHandler = (
    event: React.FormEvent<HTMLFormElement>,
    index: number
  ) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      console.log(index);
      // applicationContext.webApi
      // ?.updatePresence({
      //   id: data.rows[index].id,
      //   mobileToTeams: mobileToTeams,
      //   teamsAvailability: mobileAvailability,
      //   teamsActivity: mobileActivity,
      // })
      // .then(() => {
      //   setFrmError(false);
      //   setTimeout(() => {
      //     loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
      //   }, 500);
      // });
    } else {
      setFrmError(true);
    }
  };

  const renderForm = (index: number) => {
    return (
      <form
        className={"needs-validation p-1" + (frmError ? " was-validated" : "")}
        onSubmit={(event) => {
          submitEditHandler(event, index);
        }}
        noValidate
      >
        <Card className="border-0 shadow m-2">
          <CardBody className="bg-white bg-opacity-50 text-primary p-0 m-0">
            <div className="card bg-transparent border-0 d-flex flex-column m-4 align-items-start justify-content-center">
              <div className="card-body">
              <div className="d-flex flex-column m-2">
                    <label htmlFor={`mobilePresence-${index}`}>
                      Mobile Presence in Teams:
                    </label>
                    <select
                      className="form-select form-select-sm text-primary groups-input-size"
                      id={`mobile-${index}`}
                      value={mobilePresence}
                      onChange={(event) =>  setMobilePresence(event.target.value)}>
                      {firstOption.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                <div className="d-flex flex-column flex-md-row justify-content-center w-100">
                  <div className="d-flex flex-column m-2">
                    <label htmlFor={`mobileAvaliability-${index}`}>
                      Mobile Availability:
                    </label>
                    <select
                      className="form-select form-select-sm text-primary groups-input-size"
                      id={`mobile-${index}`}
                      value={mobileAvailability}
                      onChange={(event) =>  setMobileAvailability(event.target.value)}>
                      {secondOption.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="d-flex flex-column m-2">
                    <label htmlFor="mobileActivity">Mobile Activity:</label>
                    <select
                      className="form-select form-select-sm text-primary groups-input-size"
                      id={`mobileActivity-${index}`}
                      value={mobileActivity}
                      onChange={(event) => setMobileActivity(event.target.value) } >
                      {thirdOption[mobileAvailability].map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter className="bg-white bg-opacity-25">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-wrap">
                <div className="me-1 me-md-3 mb-2 mb-md-0">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary groupApp-edit-button"
                    onClick={() =>handleClose(index)}>
                    Close
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-primary groupApp-edit-button"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </CardFooter>
        </Card>
      </form>
    );
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Presence" onCustomerChange={handleCustomerChange} />
      </div>
      <div>
        <Table
          tableInfo={tableInfo} 
          setTableInfo={setTableInfo}
          className="table table-sm"
          data={data}
          responsive
          bordered
          rowCustomRender={rowCustomRender}
          loadData={loadData}
          isLoading={isLoading}
          toggleEditCollapse={(index: number) => {
          let tempData = Object.assign({}, data);
            //let inputData = Object.assign({}, input);
            tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
        
          setMobilePresence(tempData.rows[index].state);
          //inputData["mobileAvailability"] = tempData.rows[index].mobileAvailability;
          setMobileAvailability(tempData.rows[index].teamsAvailability);
          //inputData["mobileActivity"] = tempData.rows[index].mobileActivity;
          setMobileActivity(tempData.rows[index].teamsActivity);
          }}
          renderEditCollapse={(index: number): ReactElement => {
            return renderForm(index);
          }}
        />
      </div>
    </>
  );
};

export default Presence;
