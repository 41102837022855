import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  ReactElement,
} from "react";
import Table, { TableData, TableInfo } from "./ui/Table";
import { ApplicationContext } from "../context/ApplicationContext";
import CustomerBar from "./CustomerBar";
import "./Groups.css";
import { UbiquityGroupUsers } from "../services/web-api/application-api";

const GroupUsers: React.FC = () => {
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const applicationContext = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [groupData, setGroupData] = useState([]);
  const [group, setGroup] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.webApi?.isSuperAdmin()
      ? applicationContext.customerId
      : applicationContext.webApi?.getCredentials()?.customer ?? -1
  );
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const handleSelectAllChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;

    try {
      let response;

      if (isChecked) {
        setIsLoading(true);
        response = await applicationContext.webApi?.AddAllUbiquityGroupUser(
          customerId,
          group ?? ""
        );
      } else {
        setIsLoading(true);
        response = await applicationContext.webApi?.removeAllUbiquityGroupUser(
          group ?? ""
        );
      }

      if (response) {
        setSelectAll(isChecked);

        loadUserData(1, tableInfo.step, tableInfo.search);
      } else {
        console.error("Failed to update group membership for all users");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadUserData = useCallback(
    (currentPage: number, step: number, search: string) => {
      if (groupData.length > 0) {
        applicationContext.webApi
          ?.getUbiquityGroupUsers(
            customerId,
            group ?? "",
            currentPage,
            step,
            search
          )
          .then((response) => {
            setData(response);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } else {
        setData(initialData);
      }
    },
    [group, groupData]
  );

  const loadGroupData = useCallback(
    (customerId: number) => {
      applicationContext.webApi
        ?.getGroups(customerId, 1, 100000, "")
        .then((response) => {
          setGroupData(response.rows);
          if (response.rows.length > 0) {
            setGroup(response.rows[0].id);
          } else {
            setGroup(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [applicationContext.customerId]
  );

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    loadGroupData(applicationContext.customerId);
    setIsLoading(false);
    clearSearch();
  }, [applicationContext.customerId]);

  useEffect(() => {
    loadGroupData(customerId);
  }, [customerId]);

  useEffect(() => {
    loadUserData(1, tableInfo.step, tableInfo.search);
  }, [group, groupData]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    setSelectAll(false);
  };

  const handleGroupsSelection = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setGroup(event.target.value);
  };

  const groupList = (): ReactElement => {
    return (
      <div className="d-flex flex-row align-items-center pb-2">
        <div>
          <select
            className="form-select form-select-sm text-primary"
            name="groups"
            value={group ?? ""}
            onChange={handleGroupsSelection}
          >
            {groupData.map((group: any) => (
              <option key={group.id} value={group.id}>
                {group.displayName}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const handleCheckboxOnChange = async (
    graphId: string,
    isChecked: boolean
  ) => {
    let groupUserAdd: UbiquityGroupUsers = {
      userId: graphId,
      groupId: group ?? "",
    };

    try {
      let response;
      if (isChecked) {
        response = await applicationContext.webApi?.ubiquityGroupUser(
          groupUserAdd
        );
      } else {
        response = await applicationContext.webApi?.removeUbiquityGroupUser(
          group ?? "",
          graphId
        );
      }

      if (response) {
        setData((prevData) => {
          if (!prevData) return prevData;

          const updatedRows = prevData.rows.map((row) =>
            row.userId === graphId ? { ...row, isChecked } : row
          );
          return { ...prevData, rows: updatedRows };
        });
        loadUserData(1, tableInfo.step, tableInfo.search);
      } else {
        console.error("Failed to update user group membership");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const rowCustomRender = (i: number, j: number) => {
    let column = data.columns[j];
    let row: any = data.rows[i];
    if (column.field === "isInGroup") {
      return (
        <div className="d-flex justify-content-center ms-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={"isInGroup" + i}
              name={"isInGroup" + i}
              checked={row.isInGroup}
              onChange={() =>
                handleCheckboxOnChange(row.graphId, !row.isInGroup)
              }
            />
          </div>
        </div>
      );
    } else {
      return row[column.field] === null ? "" : row[column.field].toString();
    }
  };

  const columnCustomRender = (colField: string, colLabel: string) => {
    if (colField === "isInGroup") {
      return (
        <div className="d-flex justify-content-center">
          <input
            className="form-check-input"
            id="isInGroup-header"
            type="checkbox"
            checked={selectAll}
            onChange={(event) => handleSelectAllChange(event)}
          />
        </div>
      );
    }
    return <>{colLabel}</>;
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar
          name="Users in Groups"
          onCustomerChange={handleCustomerChange}
        />
      </div>
      <Table
        className="table table-sm"
        data={data}
        loadData={loadUserData}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
        responsive
        bordered
        renderHeader={groupList}
        isLoading={isLoading}
        rowCustomRender={rowCustomRender}
        columnCustomRender={columnCustomRender}
      />
    </>
  );
};

export default GroupUsers;
