import React from "react";

export interface InputGroupProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const InputGroup: React.FC<InputGroupProps> = (props: InputGroupProps) => {
  return (
    <div
      className={"input-group" + (props.className ? " " + props.className : "")}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default InputGroup;
