import React, { useContext, useState, useEffect } from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import SideNav from "./ui/SideNav";
import SideNavLink from "./ui/SideNavLink";
import SideNavCat from "./ui/SideNavCat";
import SideNavButton from "./ui/SideNavButton";
import "./SideBar.css";
import { LargeDesktop } from "../utils/breakpoints";

export interface SideBarProps {
  className?: string;
  id?: string;
}

type ThemeId = 1 | 2;

const themeLogos: Record<ThemeId, { long: string; short: string }> = {
  1: {
    long: "./images/logo_login_white.png",
    short: "./images/Alectoo_a_short_icon.png",
  },
  2: {
    long: "./images/jansson_logo_sidebar.png",
    short: "./images/jansson_logo_sidebar_small.png",
  },
};

const SideBar: React.FC<SideBarProps> = (props: SideBarProps) => {
  const applicationContext = useContext(ApplicationContext);
  const { theme } = applicationContext;
  const [isOpen, setIsOpen] = useState(true);
  const [logo, setLogo] = useState("");

  const definedTheme = theme as ThemeId;

  useEffect(() => {
    const logoUrl = isOpen
      ? themeLogos[definedTheme].long
      : themeLogos[definedTheme].short;
    setLogo(logoUrl);

    // if (applicationContext.theme == '2') {
    //   const logoUrl = isOpen
    //   ? "./public/images/JanssonLogo.png"
    //   : "./images/Alectoo_a_short_icon.png";
    //   setLogo(logoUrl);
    // } else {
    // const logoUrl = isOpen
    //   ? themeLogos[theme]?.long
    //   : themeLogos[theme]?.short;
    // setLogo(logoUrl);
    // }
  }, [isOpen, definedTheme]);

  const handleTrigger = () => {
    setIsOpen(!isOpen);
  };

  const renderPhoneManager = () => {
    return (
      <SideNavCat
        className={`nav-text sidenav-position ${
          isOpen ? "" : "navtext--closed"
        }`}
        name="Phone Manager"
        image="phonemanager"
        sidebarOpen={isOpen}
      >
        <SideNavLink
          className="sidebar-position sidenavlink ps-4"
          to="/phonedashboard"
          image="speedometer"
        >
          <small className="text-white">Dashboard</small>
        </SideNavLink>
        <SideNavLink className="ps-4" to="/phonenumbers" image="phonenumbers">
          <small className="text-white">Numbers</small>
        </SideNavLink>
        <SideNavLink className="ps-4" to="/phonerange" image="telephone">
          <small className="text-white">Ranges</small>
        </SideNavLink>
      </SideNavCat>
    );
  };

  const renderPresence = () => {
    return (
      <SideNavLink
        className="text-white"
        to="/presence"
        image="presence"
        tooltip="Presence"
        sidebarOpen={isOpen}
      >
        <small className={`text-white ${isOpen ? "" : "navtext--closed"} `}>
          Presence
        </small>
      </SideNavLink>
    );
  };

  const renderGroupSettings = () => {
    return (
      <SideNavCat
        className={`nav-text sidenav-position ${
          isOpen ? "" : "navtext--closed"
        }`}
        name="Group settings"
        image="groupsettings"
        sidebarOpen={isOpen}
      >
        <SideNavLink className="ps-4" to="/groups" image="groups">
          <small className="text-white">Groups</small>
        </SideNavLink>
        <SideNavLink className="ps-4" to="/groupusers" image="groupuser">
          <small className="text-white">Users in Groups</small>
        </SideNavLink>
      </SideNavCat>
    );
  };

  const renderAggregationSettings = () => {
    return (
      <SideNavCat
        className={`nav-text sidenav-position ${
          isOpen ? "" : "navtext--closed"
        }`}
        name="Aggregation"
        image="aggregation"
        sidebarOpen={isOpen}
      >
        <SideNavLink className="ps-4" to="/groupproviders" image="providers">
          <small className="text-white">Providers</small>
        </SideNavLink>
        <SideNavLink className="ps-4" to="/application" image="application">
          <small className="text-white">Application</small>
        </SideNavLink>
      </SideNavCat>
    );
  };

  const renderMonitoring = () => {
    return (
      <SideNavLink className="ps-4" to="/monitoring" image="monitoring">
        <small className="text-white">Monitoring</small>
      </SideNavLink>
    );
  };

  const renderIntegration = () => {
    return (
      <SideNavLink className="ps-4" to="/integration" image="integraton">
        <small className="text-white">Integration</small>
      </SideNavLink>
    );
  };

  const renderInternal = () => {
    return (
      <SideNavCat
        className={`nav-text sidenav-position ${
          isOpen ? "" : "navtext--closed"
        }`}
        sidebarOpen={isOpen}
        name="Internal"
        image="housegear"
      >
        <SideNavLink className="ps-4" to="/customers" image="customers">
          <small className="text-white">Customers</small>
        </SideNavLink>
      </SideNavCat>
    );
  };

  const renderLicense = () => {
    return (
      <SideNavLink
        className="ps-4"
        to="/license"
        image="license"
        // tooltip="License"
        // sidebarOpen={isOpen}
      >
        <small className="text-white">License</small>
      </SideNavLink>
    );
  };

  const renderSamwinManager = () => {
    return (
      <SideNavCat
        className={`nav-text sidenav-position ${
          isOpen ? "" : "navtext--closed"
        }`}
        name="Adhoc Manager"
        image="adhocmanager"
        sidebarOpen={isOpen}
      >
        <SideNavLink
          className="text-white ps-4"
          to="/samwinmanager"
          image="manager"
        >
          <small>Manager</small>
        </SideNavLink>
        <SideNavLink
          className="text-white ps-4"
          to="/samwinrecording"
          image="recordings"
        >
          <small>Recording</small>
        </SideNavLink>
        <SideNavLink
          className="text-white ps-4"
          to="/voicemessages"
          image="recordings"
        >
          <small>Voice Messages</small>
        </SideNavLink>
        {/* new links -Jansson */}
        <SideNavLink className="ps-4" to="/adhocgroups" image="groups">
          <small className="text-white">Groups</small>
        </SideNavLink>
        <SideNavLink className="ps-4" to="/mobilegroups" image="mobilegroups">
          <small className="text-white">Mobile Groups</small>
        </SideNavLink>
        <SideNavLink className="ps-4" to="mobileusers" image="mobileusers">
          <small className="text-white">Mobile Users</small>
        </SideNavLink>
        <SideNavLink
          className="sidebar-position ps-4"
          to="/phrases"
          image="phrases"
        >
          <small className="text-white">Phrases</small>
        </SideNavLink>
        <SideNavLink
          className="text-white sidebar-position ps-4"
          to="/messages"
          image="messages"
        >
          Messages
        </SideNavLink>
      </SideNavCat>
    );
  };

  return (
    <div className={props.className} id={props.id}>
      <SideNav
        className={`side-bar-container bg-primary ${
          isOpen ? "sidebar--open" : "sidebar--closed"
        } `}
        logo={logo}
        logoHeight={isOpen ? "70px" : "35px"}
      >
        <div className="gradients" style={{ background: "var(--side-bar-bg)" }}>
          <LargeDesktop>
            <SideNavButton
              className="text-light"
              image="hamburgerMenu"
              sidebarOpen={isOpen}
              onClick={handleTrigger}
              fill="white"
              tooltip="Navigation menu"
            />
          </LargeDesktop>

          {/* 1 */}
          <SideNavLink
            className="text-white"
            to="/"
            image="dashboard"
            sidebarOpen={isOpen}
            tooltip="Dashboard"
          >
            <small className={` text-white ${isOpen ? "" : "navtext--closed"}`}>
              Dashboard
            </small>
          </SideNavLink>
          {/* 2 */}

          {applicationContext.webApi?.isSuperAdmin() ||
          applicationContext.webApi?.isAdmin()
            ? renderPhoneManager()
            : null}

          {/* 3 */}
          {applicationContext.webApi?.isSuperAdmin() ||
          applicationContext.webApi?.isAdmin()
            ? renderPresence()
            : null}
          {/* 4 */}
          <SideNavLink
            className="text-white"
            to="/alectooapp"
            image="alectooapp"
            sidebarOpen={isOpen}
            tooltip="Alectoo App"
          >
            <small className={`text-white ${isOpen ? "" : "navtext--closed"}`}>
              Alectoo App
            </small>
          </SideNavLink>
          {/* 5 */}
          {applicationContext.webApi?.isSuperAdmin() ||
          applicationContext.webApi?.isAdmin() ||
          applicationContext.webApi?.isPartner()
            ? renderGroupSettings()
            : null}

          {/* 6 */}
          {applicationContext.webApi?.isSuperAdmin()
            ? renderAggregationSettings()
            : null}

          {/* 7 */}
          <SideNavCat
            className={`nav-text ${isOpen ? "" : "navtext--closed"}`}
            name="Administration"
            image="gear"
            sidebarOpen={isOpen}
          >
            {applicationContext.webApi?.isSuperAdmin() ||
            applicationContext.webApi?.isPartner()
              ? renderIntegration()
              : null}
            {applicationContext.webApi?.isSuperAdmin() ||
            applicationContext.webApi?.isPartner() ||
            applicationContext.webApi?.isAdmin()
              ? renderMonitoring()
              : null}

            <SideNavLink
              className="sidebar-position ps-4"
              to="/users"
              image="users"
            >
              <small className="text-white">Users</small>
            </SideNavLink>
            <SideNavLink className="text-white ps-4" to="/faq" image="faq">
              <small>FAQ</small>
            </SideNavLink>
            <SideNavLink
              className="text-white ps-4"
              to="/manuals"
              image="manuals"
            >
              <small>Manuals</small>
            </SideNavLink>
            {applicationContext.webApi?.isSuperAdmin() ||
            applicationContext.webApi?.isAdmin()
              ? renderLicense()
              : null}
          </SideNavCat>
          {/* 8 - 9 */}
          {applicationContext.webApi?.isSuperAdmin() ? renderInternal() : null}
          {/* {applicationContext.webApi?.isSuperAdmin() ||
          applicationContext.webApi?.isAdmin()
            ? renderLicense()
            : null} */}
          {/* 10 */}
          {applicationContext.webApi?.isSuperAdmin() ||
          applicationContext.webApi?.isPartner()
            ? renderSamwinManager()
            : null}
        </div>
      </SideNav>
    </div>
  );
};

export default SideBar;
