import React, { useState, useContext, useEffect } from "react";
import CustomerBar from "./CustomerBar";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import CardHeader from "./ui/CardHeader";
import "./License.css";
import { ApplicationContext } from "../context/ApplicationContext";
import moment from "moment";

interface LicenseData {
  licenseInfo: string;
  licenseAmount: number;
  activeCount: number;
  activatedDate: any;
  expireDate: any;
}

const License: React.FC = () => {
  const [licenseData, setLicenseData] = useState<LicenseData[] | null>(null);
  const applicationContext = useContext(ApplicationContext);

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await applicationContext.webApi?.getLicenses(
          applicationContext.customerId
        );
        if (response) {
          setLicenseData(response);
        }
      } catch (error) {
        console.error("Error fetching license data:", error);
      }
    };

    fetchLicenseData();
  }, [applicationContext.customerId]);

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="License" />
      </div>
      <div className="d-flex license-container mb-3 mt-3 pl-3">
        <div className="d-flex flex-column w-100 h-100 mb-3">

          {licenseData?.length !== 0 ? (

           licenseData?.map((data: LicenseData) => {
            return (
              <div className="d-flex flex-row" key={data.licenseInfo}>
                <Card className="border border-primary shadow license-card-format m-3">
                  <CardHeader className="border border-primary border-top-0 border-start-0 border-end-0">
                    <strong className="license-h-font text-primary">
                      {data.licenseInfo}
                    </strong>
                  </CardHeader>
                  <CardBody>
                    <form className="form">
                      <div className="d-flex flex-column mb-3">
                        <div className="d-flex flex-row align-items-center w-100 mb-3 pe-2">
                          <label className="form-label text-primary pe-3 mb-0">
                            License Count:
                          </label>
                          <label className="form-label text-primary pe-3 mb-0">
                            {data.licenseAmount}
                          </label>
                        </div>
                        <div className="d-flex flex-row align-items-center w-100 mb-3 pe-2">
                          <label className="form-label text-primary pe-3 mb-0">
                            Active Count:
                          </label>
                          <label className="form-label text-primary pe-3 mb-0">
                            {data.activeCount}
                          </label>
                        </div>
                        <div className="d-flex flex-row align-items-center w-100 mb-3 pe-2">
                          <label className="form-label text-primary pe-3 mb-0">
                            License Activated:
                          </label>
                          <label className="form-label text-primary pe-3 mb-0">
                            {moment(new Date(data.activatedDate + "Z")).format(
                              "DD-MM-YYYY"
                            )}
                          </label>
                        </div>
                        <div className="d-flex flex-row align-items-center w-100 mb-3 pe-2">
                          <label className="form-label text-primary pe-3 mb-0">
                            License Expire:
                          </label>
                          <label className="form-label text-primary pe-3 mb-0">
                            {moment(new Date(data.expireDate + "Z")).format(
                              "DD-MM-YYYY"
                            )}
                          </label>
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </div>
            ); 
           })
          ) : (
              <div className="d-flex flex-row">
              <Card className="border border-primary shadow license-card-format m-3">
              <CardHeader className="border border-primary border-top-0 border-start-0 border-end-0">
                <strong className="license-h-font text-primary">
                 Licenses Info
                </strong>
              </CardHeader>
              <CardBody>
              <big className="text-primary">There are no licenses for the selected customer.</big>
              
               </CardBody>
               </Card>
               </div>
            
           )}
        </div>
      </div>
    </>
  );
};

export default License;
