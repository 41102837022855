import { ReactComponent as ImageIcon } from "./icons/Image.svg";
import { ReactComponent as Previous } from "./icons/BackwardStep.svg";
import { ReactComponent as Next } from "./icons/ForwardStep.svg";
import { ReactComponent as PlayCircle } from "./icons/CirclePlay.svg";
import { ReactComponent as Repeat } from "./icons/Repeat.svg";
import { ReactComponent as VolumeHigh } from "./icons/VolumeHigh.svg";
import { ReactComponent as PauseCircle } from "./icons/Pause-Circle.svg";
import { useEffect, useRef, useState } from "react";
import AudioVolumeInput from "./AudioVolumeInput";
import AudioProgressBar from "./AudioProgressBar";

export interface AudioPlayerProps {
  currentTrack: Track | undefined;
  trackIndex: number;
  trackCount: number;
  onNext: () => void;
  onPrev: () => void;
}

type Track = {
  id: string;
  file: string;
  title: string;
  author: string;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  currentTrack,
  trackIndex,
  trackCount,
  onNext,
  onPrev,
}: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currrentProgress, setCurrrentProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted] = useState(false);
  const [isRepeating, setIsRepeating] = useState(false);

  const formatDurationDisplay = (duration: number) => {
    const min = Math.floor(duration / 60);
    const sec = Math.floor(duration - min * 60);

    const formatted = [min, sec].map((n) => (n < 10 ? "0" + n : n)).join(":");

    return formatted;
  };

  const durationDisplay = formatDurationDisplay(duration);
  const elapsedDisplay = formatDurationDisplay(currrentProgress);

  useEffect(() => {
    if (audioRef.current && currentTrack) {
      audioRef.current.pause();
      audioRef.current.src = currentTrack.file;
      audioRef.current.load();
      const timeout = setTimeout(() => {
        audioRef.current?.play();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [currentTrack]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  });

  const handleNext = () => {
    onNext();
  };

  const handlePrev = () => {
    onPrev();
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  const handleBufferProgress: React.ReactEventHandler<HTMLAudioElement> = (
    e
  ) => {
    const audio = e.currentTarget;
    const dur = audio.duration;
    if (dur > 0) {
      for (let i = 0; i < audio.buffered.length; i++) {
        if (
          audio.buffered.start(audio.buffered.length - 1 - i) <
          audio.currentTime
        ) {
          const bufferedLength = audio.buffered.end(
            audio.buffered.length - 1 - i
          );
          setBuffered(bufferedLength);
          break;
        }
      }
    }
  };

  const handleMuteUnmute = () => {
    if (!audioRef.current) return;

    if (audioRef.current.volume !== 0) {
      audioRef.current.volume = 0;
    } else {
      audioRef.current.volume = 1;
    }
  };

  const handleVolumeChange = (volumeValue: number) => {
    if (audioRef.current) {
      audioRef.current.volume = volumeValue;
      setVolume(volumeValue);
    }
  };

  const toggleRepeat = () => {
    setIsRepeating(!isRepeating);
  };

  const handleEnded = () => {
    if (isRepeating) {
      audioRef.current?.play();
    } else {
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (isReady) {
      console.log("Audio is ready");
    }
  }, [isReady]);

  return (
    <div className="d-flex flex-row w-100 justify-content-around align-items-center py-2">
      {currentTrack && (
        <audio
          ref={audioRef}
          onDurationChange={(e) => setDuration(e.currentTarget.duration)}
          onPlaying={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onEnded={handleEnded}
          onCanPlay={() => {
            setIsReady(true);
          }}
          onTimeUpdate={(e) => {
            setCurrrentProgress(e.currentTarget.currentTime);
            handleBufferProgress(e);
          }}
          onProgress={handleBufferProgress}
          onVolumeChange={(e) => setVolume(e.currentTarget.volume)}>
          <source type="audio/mp3" src={currentTrack.file} />
          <track kind="captions" src="path/to/cations.vtt" />
        </audio>
      )}
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column align-self-center me-3">
            <ImageIcon
              fill="#36454F"
              style={{ height: "40px", width: "40px" }}
            />
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row p-0 m-0">
              <p className="p-0 m-0">{currentTrack?.title ?? "Track Title"}</p>
            </div>
            <div className="d-flex flex-row">
              <p className="p-0 m-0">
                <small>{currentTrack?.author ?? "Track Author"}</small>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Options (Add/More) */}
      {/* <div className="d-flex flex-column align-items-center">
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-column align-self-center me-2">
              <AddCircle
                fill="#36454F"
                style={{ height: "18px", width: "18px" }}
              />
            </div>
            <div className="d-flex flex-column align-self-center">
              <Options
                fill="#36454F"
                style={{ height: "18px", width: "18px" }}
              />
            </div>
          </div>
        </div> */}

      {/* controls */}
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column align-self-center">
            <Previous
              fill="#36454F"
              style={{ height: "18px", width: "18px" }}
              onClick={handlePrev}
              aria-disabled={trackIndex === 0}
            />
          </div>
          <div className="d-flex flex-column align-self-center mx-2">
            {isPlaying ? (
              <PauseCircle
                fill="#36454F"
                style={{ height: "32px", width: "32px" }}
                onClick={togglePlayPause}
              />
            ) : (
              <PlayCircle
                fill="#36454F"
                style={{ height: "32px", width: "32px" }}
                onClick={togglePlayPause}
              />
            )}
          </div>
          <div className="d-flex flex-column align-self-center">
            <Next
              fill="#36454F"
              style={{ height: "18px", width: "18px" }}
              onClick={handleNext}
              aria-disabled={trackIndex === trackCount - 1}
            />
          </div>
        </div>
      </div>

      {/* Track Duration Time & Bar */}
      <div className="d-flex flex-column align-items-center ">
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column align-self-center">
            <p className="m-0">{elapsedDisplay}</p>
          </div>
          <div className="d-flex flex-column align-self-center mx-3">
            <div className="m-0">
              <AudioProgressBar
                className=""
                duration={duration}
                currentProgress={currrentProgress}
                buffered={buffered}
                onChange={(e) => {
                  if (!audioRef.current) return;
                  audioRef.current.currentTime = e.currentTarget.valueAsNumber;
                  setCurrrentProgress(e.currentTarget.valueAsNumber);
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column align-self-center">
            <p className="m-0">{durationDisplay}</p>
          </div>
        </div>
      </div>

      {/* Repeat Btn */}
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column align-self-center">
            <Repeat
              fill={isRepeating ? "#36454F" : "#a9a9a9"}
              style={{ height: "18px", width: "18px" }}
              onClick={toggleRepeat}
            />
          </div>
        </div>
      </div>

      {/* Volume Controler */}
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column align-self-center me-2">
            <VolumeHigh
              fill="#36454F"
              style={{
                height: "18px",
                width: "18px",
                opacity: isMuted ? 0.2 : 1,
              }}
              onClick={handleMuteUnmute}
            />
          </div>
          <div className="d-flex flex-column align-self-center">
            <AudioVolumeInput
              volume={volume}
              onVolumeChange={handleVolumeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
