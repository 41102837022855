import React from "react";

export interface CardProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Card: React.FC<CardProps> = (props: CardProps) => {
  return (
    <div
      className={"card" + (props.className ? " " + props.className : "")}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default Card;
