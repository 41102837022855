import React from "react";
import "./AudioVolumeInput.css";

export interface AudioVolumeInputProps {
  volume: number;
  onVolumeChange: (volume: number) => void;
}

const AudioVolumeInput: React.FC<AudioVolumeInputProps> = ({
  volume,
  onVolumeChange,
}: AudioVolumeInputProps) => {
  return (
    <input
      aria-label="volume"
      name="volume"
      type="range"
      min={0}
      step={0.05}
      max={1}
      value={volume}
      className="volume-slider"
      onChange={(e) => {
        onVolumeChange(e.currentTarget.valueAsNumber);
      }}
    />
  );
};

export default AudioVolumeInput;
