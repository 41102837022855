import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  FormEvent,
} from "react";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import CardHeader from "./ui/CardHeader";
import CardFooter from "./ui/CardFooter";
import CustomerBar from "./CustomerBar";
import { ApplicationContext } from "../context/ApplicationContext";
import "./Integration.css";

const Integration: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const [tenantId, setTenantId] = useState("");
  const [serverTenandId, setServerTenandId] = useState("");
  const [userTenandId, setUserTenandId] = useState("");
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );
  const [ubiquityCustomerId, setUbiquityCustomerId] = useState(0);
  const [userGraphId, setUserGraphId] = useState("");
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [frmIntegrationError, setFrmIntegrationError] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [saveIntegrationMessage, setSaveIntegrationMessage] = useState("");
  const [domains, setDomains] = useState<string[]>([]);
  const [deleteDomain, setDeleteDomain] = useState("");

  const handleTenantOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTenantId(value);
  };

  const handleUserOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserTenandId(value);
  };

  const handleAdminCopyLink = () => {
    if (tenantId.length > 0) {
      const url =
        "https://login.microsoftonline.com/" +
        tenantId +
        "/v2.0/adminconsent?client_id=97c349b0-9c17-4849-b043-f3241373e719&state=" +
        ubiquityCustomerId +
        "&redirect_uri=https://cloudlink.alectoo.com/api/data/adminconsent&scope=https://graph.microsoft.com/.default";
      navigator.clipboard.writeText(url);
    }
  };

  const handleUserCopyLink = () => {
    const url =
      "https://login.microsoftonline.com/" +
      tenantId +
      "/oauth2/v2.0/authorize?client_id=97c349b0-9c17-4849-b043-f3241373e719&response_type=code&redirect_uri=https://cloudlink.alectoo.com/api/data/userconsent&response_mode=query&scope=" +
      encodeURIComponent("offline_access Presence.Read.All User.Read") +
      "&state=" +
      ubiquityCustomerId;
    if (tenantId.length > 0) {
      navigator.clipboard.writeText(url);
    }
  };

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
  }, [applicationContext.customerId]);

  useEffect(() => {
    if (customerId > 0) {
      applicationContext.webApi
        ?.getCustomerIntegration(customerId)
        .then((customerData) => {
          setServerTenandId(customerData.ubiquityTenantId);
          setTenantId(customerData.ubiquityTenantId);
          setUserTenandId(customerData.ubiquityTenantId);
          setUbiquityCustomerId(customerData.ubiquityCustomerId);
          setUserGraphId(customerData.ubiquityGraphUser);
        })
        .then(() => {
          applicationContext.webApi
            ?.getDomains(customerId)
            .then((domainData) => {
              setDomains(domainData.domains.split(";"));
            })
            .catch((error) => {
              console.log(error);
              setSaveIntegrationMessage("No domains!");
            });
        });

      applicationContext.webApi
        ?.getIntegrationUser(customerId)
        .then((userData) => {
          if (userData.userName.indexOf("@") > 0) {
            const userName = userData.userName.split("@")[0];
            let tempInput = Object.assign({}, input);
            tempInput["integration_user_name"] = userName;
            setInput(tempInput);
          } else {
            input["integration_user_name"] = "";
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const submitIntegrationUserHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (event.currentTarget.reportValidity()) {
      let userName = input["integration_user_name"];
      let password = input["integration_password"];
      applicationContext.webApi
        ?.saveIntegrationUser(customerId, userName, password)
        .then(() => {
          setSaveMessage("Integration user saved successfully!");
        })
        .catch((error) => {
          console.log(error);
          setSaveMessage("Failed to save integration user!");
        });
      setFrmIntegrationError(false);
    } else {
      setSaveMessage("Failed to save integration user");
      setFrmIntegrationError(true);
    }
  };

  const submitDomainHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (event.currentTarget.reportValidity()) {
      let tempDomains = domains.join(";");
      applicationContext.webApi
        ?.saveDomains(customerId, tempDomains)
        .then(() => {
          setTimeout(() => {
            applicationContext.webApi
              ?.getDomains(customerId)
              .then((domainData) => {
                if (domainData.length > 0) {
                  setDomains(domainData.domains.split(";"));
                }
              });
            setSaveIntegrationMessage("Domains successfully saved!");
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          setSaveIntegrationMessage("Failed to save domains!");
        });
    }
  };

  const handleAdd = () => {
    let new_domain = input["integration_domain"];
    if (new_domain !== "") {
      const newData = [...domains, new_domain];
      console.log(newData);
      setDomains(newData);
      input["integration_domain"] = "";
    }
    setSaveIntegrationMessage("");
  };

  const handleRemoveOption = (value: any) => {
    const updatedData = domains.filter((item) => item !== value);
    setDomains(updatedData);
    setSaveIntegrationMessage("");
  };

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
  };

  return (
    <>
      <div className="pt-3">
        <CustomerBar
          name="Integration"
          onCustomerChange={handleCustomerChange}
        />
      </div>
      <Card className="border border-primary integration-card-format mb-3 mt-4 pl-3">
        <CardHeader className="bg-white border border-primary border-top-0 border-start-0 border-end-0">
          <strong className="integration-h-font text-primary">
            Administration Consent
          </strong>
        </CardHeader>
        <CardBody>
          <form className="form">
            <div className="d-flex flex-column mb-3">
              <div className="d-flex flex-row align-items-center mb-3">
                <p className="text-primary">
                  Administration consent is needed so that Alectoo Application
                  can be allowed to run on the tenant. Users can be downloaded,
                  presence can be changed ...
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <label
                  className="text-primary form-label pe-3 mb-0"
                  htmlFor={"tenant_id"}>
                  {" "}
                  Tenant ID:
                </label>
                <input
                  className="form-control form-control-sm text-primary integration-input flex-shrink-1"
                  id="tenant_id"
                  onChange={handleTenantOnChange}
                  value={tenantId || ""}
                  required
                />
                <div className="d-flex flex-row align-items-center ms-3">
                  <span
                    className={
                      "invalid-feedback" +
                      (tenantId.length === 0 ? " d-block" : "")
                    }>
                    Tenant ID is required!
                  </span>
                </div>
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter className="border border-primary border-bottom-0 border-start-0 border-end-0">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <label
              className={
                serverTenandId.length > 0 ? "text-success" : "text-danger"
              }>
              {tenantId.length > 0
                ? "Admin consent has been approved!"
                : "Admin consent has not been approved!"}
            </label>
            <button
              className="btn btn-primary integration-button"
              onClick={handleAdminCopyLink}>
              Copy Link
            </button>
          </div>
        </CardFooter>
      </Card>
      <Card className="border border-primary integration-card-format mb-3 mt-2 pl-3">
        <CardHeader className="bg-white border border-primary border-top-0 border-start-0 border-end-0">
          <strong className="integration-h-font text-primary">
            User Consent
          </strong>
        </CardHeader>
        <CardBody>
          <form className="form">
            <div className="d-flex flex-column mb-3">
              <div className="d-flex flex-row align-items-center mb-3">
                <p className="text-primary">
                  User consent is needed so that Alectoo Application can get
                  presence for users.
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <label
                  className="text-primary form-label pe-3 mb-0"
                  htmlFor={"tenant_id"}>
                  Tenant ID:
                </label>
                <input
                  className="form-control form-control-sm text-primary integration-input flex-shrink-1"
                  id="user_tenant_id"
                  type="text"
                  onChange={handleUserOnChange}
                  value={userTenandId || ""}
                  required
                />
                <div className="d-flex flex-row align-items-center ms-3">
                  <span
                    className={
                      "invalid-feedback" +
                      (userTenandId.length === 0 ? " d-block" : "")
                    }>
                    Tenant ID is required!
                  </span>
                </div>
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter className="border border-primary border-bottom-0 border-start-0 border-end-0">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <label
              className={
                userGraphId.length > 0 ? "text-success" : "text-danger"
              }>
              {userGraphId.length > 0
                ? "User consent has been approved!"
                : "User consent has not been approved!"}
            </label>
            <button
              className="btn btn-primary integration-button"
              onClick={handleUserCopyLink}>
              Copy Link
            </button>
          </div>
        </CardFooter>
      </Card>

      <Card className="mb-2border border-primary integration-card-format mb-4 mt-2 pl-3">
        <form
          className={
            "needs-validation" + (frmIntegrationError ? " was-validated" : "")
          }
          onSubmit={(event) => {
            submitIntegrationUserHandler(event);
          }}
          noValidate>
          <CardHeader className="bg-white border border-primary border-top-0 border-start-0 border-end-0">
            <strong className="integration-h-font text-primary">
              Integration User
            </strong>
          </CardHeader>
          <CardBody>
            <div className="d-flex flex-column align-items-center mb-md-3">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100">
                <p className="text-primary">
                  Integration user is the user used in AlectionIntegration
                  service to connect to AlectooCloud.
                </p>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 mb-3">
                <label
                  className="text-primary form-label pe-3 mb-0"
                  htmlFor="integration_user_name">
                  User Name:
                </label>
                <input
                  className="form-control form-control-sm text-primary integration-input"
                  name="integration_user_name"
                  id="integration_user_name"
                  value={input["integration_user_name"] || ""}
                  onChange={handleInputOnChange}
                  pattern="^[a-z0-9]{1,10}$"
                  maxLength={15}
                  required
                />
                <span className="invalid-feedback ml-2">
                  Required! Can contain numbers and letters only. Max length is
                  15.
                </span>
                <label
                  className="text-primary form-label ps-md-2 mb-0"
                  htmlFor="email">
                  @alectoo.com
                </label>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="text-primary form-label mb-0 integration-password-label"
                  htmlFor="integration_password">
                  Password:
                </label>
                <input
                  className="form-control form-control-sm text-primary integration-input"
                  name="integration_password"
                  id="integration_password"
                  type="password"
                  value={input["integration_password"] || ""}
                  onChange={handleInputOnChange}
                  required
                />
                <span className="invalid-feedback ml-2">Required!</span>
              </div>
            </div>
          </CardBody>
          <CardFooter  className="border border-primary border-bottom-0 border-start-0 border-end-0">
            <div className="d-flex flex-row justify-content-end align-items-center">
              {saveMessage.length > 0 && (
                <label
                  className={
                    "me-2 " +
                    (saveMessage.startsWith("Failed")
                      ? "text-danger"
                      : "text-success")
                  }>
                  {saveMessage}
                </label>
              )}
              <div>
                <button
                  className="btn btn-primary integration-button"
                  type="submit">
                  Save
                </button>
              </div>
            </div>
          </CardFooter>
        </form>
      </Card>
      <Card className="border border-primary integration-card-format mb-4 mt-2 pl-3">
        <CardHeader className="bg-white border border-primary border-top-0 border-start-0 border-end-0">
          <strong className="integration-h-font text-primary">Domains</strong>
        </CardHeader>
        <CardBody>
          <form className="form">
            <div className="d-flex flex-column align-items-center mb-2">
              <div
                id="refreshContent"
                className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 mb-3">
                <label className="text-primary form-label pe-3 mb-0">
                  Select domain name:
                </label>
                <select
                  className="form-select integration-select text-primary"
                  id="domain_select"
                  multiple
                  aria-label="domain selection"
                  onChange={(event) => setDeleteDomain(event.target.value)}>
                  {domains.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger user-edit-button text-light ms-md-3 mt-2 mt-md-5"
                    onClick={() => handleRemoveOption(deleteDomain)}>
                    Remove
                  </button>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100">
                <label
                  className="text-primary form-label pe-3 mb-0"
                  htmlFor="integration_domain">
                  Enter domain name:
                </label>
                <input
                  className="form-control form-control-sm text-primary integration-input"
                  name="integration_domain"
                  id="integration_domain"
                  value={input["integration_domain"] || ""}
                  onChange={handleInputOnChange}
                />
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary user-edit-button text-light ms-md-3 mt-2 mt-md-0"
                    onClick={handleAdd}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter className="border border-primary border-bottom-0 border-start-0 border-end-0">
          <form
            className="form"
            onSubmit={(event) => {
              submitDomainHandler(event);
            }}>
            <div className="d-flex flex-row align-items-center justify-content-end">
              {saveIntegrationMessage.length > 0 && (
                <label
                  className={
                    "me-2 " +
                    (saveIntegrationMessage.startsWith("Failed")
                      ? "text-danger"
                      : "text-success")
                  }>
                  {saveIntegrationMessage}
                </label>
              )}
              <div>
                <button
                  className="btn btn-primary integration-button"
                  type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
        </CardFooter>
      </Card>
    </>
  );
};

export default Integration;
