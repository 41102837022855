import React, { useRef, useState } from "react";

import CustomerBar from "./CustomerBar";
import Card from "./ui/Card";
import { ReactComponent as ImageIcon } from "./icons/Image.svg";
import AudioPlayer from "./AudioPlayer";
import { ReactComponent as PlaySolidIcon } from "./icons/play-solid.svg";
import "./VoiceMessages.css";

const tracks = [
  {
    id: "1",
    file: "./sound/samplememo.mp3",
    title: "Sample Track",
    author: "web sample",
    dateAdded: "Jul 1st  2021",
    duration: "00:15",
  },
  {
    id: "2",
    file: "./sound/sample_MK.mp3",
    title: "Mk language",
    author: "free source",
    dateAdded: "May 1st  2024",
    duration: "00:35",
  },
  {
    id: "3",
    file: "./sound/sample_UK.mp3",
    title: "Track Uk",
    author: "online sample",
    dateAdded: "Jan 1st  2024",
    duration: "00:55",
  },
  {
    id: "1",
    file: "./sound/samplememo.mp3",
    title: "Sample Track",
    author: "web sample",
    dateAdded: "Jul 1st  2021",
    duration: "00:15",
  },
  {
    id: "2",
    file: "./sound/sample_MK.mp3",
    title: "Mk language",
    author: "free source",
    dateAdded: "May 1st  2024",
    duration: "00:35",
  },
  {
    id: "3",
    file: "./sound/sample_UK.mp3",
    title: "Track Uk",
    author: "online sample",
    dateAdded: "Jan 1st  2024",
    duration: "00:55",
  },
];

const VoiceMessages: React.FC = () => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(-1);
  const currentTrack = tracks[currentTrackIndex];
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Voice Messages" />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-around playlists-cards">
        <Card className="border border-primary shadow rounded w-25 me-2">
          <div className="d-flex flex-row flex-md-column w-100 h-100 justify-content-start align-items-center playlists-card py-1">
            <p className="fw-bold pb-3">Import</p>
            <div className="d-flex flex-column w-100 align-items-center">
              <div className="d-flex flex-row w-100 justify-content-evenly pb-1">
                <ImageIcon
                  fill="#36454F"
                  style={{ height: "30px", width: "30px" }}
                />
                <p>Playlist Name</p>
              </div>
              <div className="d-flex flex-row w-100 justify-content-evenly pb-1">
                <ImageIcon
                  fill="#36454F"
                  style={{ height: "30px", width: "30px" }}
                />
                <p>Playlist Name</p>
              </div>
              <div className="d-flex flex-row w-100 justify-content-evenly pb-1">
                <ImageIcon
                  fill="#36454F"
                  style={{ height: "30px", width: "30px" }}
                />
                <p>Playlist Name</p>
              </div>
              <div className="d-flex flex-row w-100 justify-content-evenly pb-1">
                <ImageIcon
                  fill="#36454F"
                  style={{ height: "30px", width: "30px" }}
                />
                <p>Playlist Name</p>
              </div>
              <div className="d-flex flex-row w-100 justify-content-evenly pb-1">
                <ImageIcon
                  fill="#36454F"
                  style={{ height: "30px", width: "30px" }}
                />
                <p>Playlist Name</p>
              </div>
            </div>
          </div>
        </Card>

        {/* tracks */}
        <Card className="border border-primary shadow rounded w-75 py-2 ms-2">
          <div className="playlists-card d-flex flex-row flex-md-column w-100 h-100 justify-content-start align-items-center py-1">
            {/* headers */}
            <div className="d-flex flex-row w-100 justify-content-between py-1">
              <div className="d-flex flex-column align-items-center col-lg-4">
                <div className="d-flex flex-row justify-content-center">
                  <p className="fw-bold">Track Title</p>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center col-lg-4">
                <div className="d-flex flex-row justify-content-center">
                  <p className="fw-bold">Date Added</p>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center col-lg-4">
                <div className="d-flex flex-row justify-content-center">
                  <p className="fw-bold">Duration</p>
                </div>
              </div>
            </div>

            {/* content */}
            {tracks.map((track, index) => (
              <div
                key={track.id}
                className={`d-flex flex-row w-100 justify-content-between py-1 playlist-row
                ${currentTrackIndex === index ? " active " : ""}
                ${currentTrackIndex === index && isPlaying ? "playing" : ""}`}>
                <div className="d-flex flex-column align-items-center col-lg-4">
                  <div className="d-flex flex-row justify-content-center w-100">
                    <div className="d-flex flex-row justify-content-center pe-4">
                      {/* button */}
                      <div className="d-flex flex-row align-self-center pe-3">
                        <button
                          className="border-0 bg-transparent"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentTrackIndex(index);
                            togglePlay();
                          }}>
                          {currentTrackIndex === index && isPlaying ? (
                            <PlaySolidIcon
                              fill="#36454F"
                              className="justify-content-start"
                              style={{ height: "12px", width: "12px" }}
                              onClick={togglePlay}
                            />
                          ) : (
                            <p className="p-0 m-0">{track.id}</p>
                          )}
                        </button>
                      </div>
                      {/* image */}
                      <div className="d-flex flex-column align-self-center pe-3">
                        <ImageIcon
                          fill="#36454F"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </div>
                      <div className="d-flex flex-column ps-2">
                        <div className="d-flex flex-row p-0 m-0">
                          <p className="p-0 m-0">{track.title}</p>
                        </div>
                        <div className="d-flex flex-row">
                          <p className="p-0 m-0">
                            <small>{track.author}</small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* dateAdded */}
                <div className="d-flex flex-column align-items-center col-lg-4">
                  <p>{track.dateAdded}</p>
                </div>
                {/* duration */}
                <div className="d-flex flex-column align-items-center col-lg-4">
                  <p>{track.duration}</p>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>

      {/* audio player */}
      <Card className="border border-primary shadow rounded m-0 ">
        <AudioPlayer
          key={currentTrackIndex}
          currentTrack={currentTrack}
          trackCount={tracks.length}
          trackIndex={currentTrackIndex}
          onNext={() => setCurrentTrackIndex((i) => i + 1)}
          onPrev={() => setCurrentTrackIndex((i) => i - 1)}
        />
      </Card>
    </>
  );
};

export default VoiceMessages;
