import React, { useState, useContext, useEffect, FormEvent } from "react";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import CardHeader from "./ui/CardHeader";
import CardFooter from "./ui/CardFooter";
import CustomerBar from "./CustomerBar";
import { ApplicationContext } from "../context/ApplicationContext";
import "./MonitorMail.css";
import { Monitoring } from "../services/web-api/application-api";

const MonitorMail: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );
  const [input, setInput] = useState<{ [key: string]: string | boolean }>({});
  const [saveMessage, setSaveMessage] = useState("");
  const [monitorMails, setMonitorMails] = useState<string[]>([]);
  const [deleteMail, setDeleteMail] = useState("");

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    setSaveMessage("");
  };

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleAdd = () => {
    let new_mail = input["monitor_mail"] as string;
    if (new_mail !== "") {
      const newData = [...monitorMails, new_mail];
      console.log(newData);
      setMonitorMails(newData);
      input["monitor_mail"] = "";
    }
    setSaveMessage("");
  };

  const handleRemoveOption = (value: any) => {
    const updatedData = monitorMails.filter((item) => item !== value);
    setMonitorMails(updatedData);
    setSaveMessage("");
  };

  const submitMmailHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (event.currentTarget.reportValidity()) {
      let tempMails = monitorMails.join(";");
      let monitoring: Monitoring = {
        customerId: customerId,
        emails: tempMails,
        serviceInactivityEmail: input["service_inactivity"] as boolean,
        teamsInactivityEmail: input["presence_inactivity"] as boolean,
        mobileInactivityEmail: input["mobile_presence_inactivity"] as boolean,
      };

      applicationContext.webApi
        ?.upsertMonitoring(monitoring)
        .then(() => {
          setSaveMessage("Monitored e-mails successfully saved!");
        })
        .catch((error) => {
          console.log(error);
          setSaveMessage("Failed to save monitored e-mails!");
        });
    }
  };

  useEffect(() => {
    // api for getting and setting e-mails for monitoring
    applicationContext.webApi
      ?.getMonitoring(customerId)
      .then((monitoring: Monitoring) => {
        if (monitoring) {
          let mails = monitoring.emails.split(";");
          setMonitorMails(mails);
          setInput({
            ...input,
            ["service_inactivity"]: monitoring.serviceInactivityEmail,
            ["presence_inactivity"]: monitoring.teamsInactivityEmail,
            ["mobile_presence_inactivity"]: monitoring.mobileInactivityEmail,
          });
        } else {
          setMonitorMails([]);
          setInput({
            ...input,
            ["service_inactivity"]: false,
            ["presence_inactivity"]: false,
            ["mobile_presence_inactivity"]: false,
          });
        }
      });
  }, [customerId]);

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    setSaveMessage("");
  }, [applicationContext.customerId]);

  return (
    <>
      <div className="pt-3">
        <CustomerBar
          name="Monitoring"
          onCustomerChange={handleCustomerChange}
        />
      </div>
      <Card className="border border-primary monitor-card-format mb-4 mt-2 pl-3">
        <CardHeader className="bg-white">
          <strong className="monitor-h-font text-primary">
            Monitor E-Mail addresses
          </strong>
        </CardHeader>
        <CardBody className="border border-primary border-start-0 border-end-0">
          <form className="form">
            <div className="d-flex flex-column align-items-center mb-2">
              <div
                id="refreshContent"
                className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 mb-3"
              >
                <label className="text-primary form-label pe-4 mb-0">
                  Monitored e-mails:
                </label>
                <select
                  className="form-select monitor-select text-primary"
                  id="mail_select"
                  multiple
                  aria-label="mail selection"
                  onChange={(event) => setDeleteMail(event.target.value)}
                >
                  {monitorMails.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger user-edit-button text-light ms-md-3 mt-2 mt-md-5"
                    onClick={() => handleRemoveOption(deleteMail)}
                  >
                    Remove
                  </button>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100">
                <label
                  className="text-primary form-label pe-2 mb-0"
                  htmlFor="monitor_mail"
                >
                  Enter e-mail address:
                </label>
                <input
                  className="form-control form-control-sm text-primary monitor-input"
                  type="email"
                  name="monitor_mail"
                  id="monitor_mail"
                  value={(input["monitor_mail"] || "") as string}
                  onChange={handleInputOnChange}
                />
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary user-edit-button text-light ms-md-3 mt-2 mt-md-0"
                    onClick={handleAdd}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 mb-3 mt-3">
                <label
                  className="text-primary form-label pe-5 mb-0"
                  htmlFor="checkbox_mail"
                >
                  Send e-mail on:
                </label>
                <div className="border border-primary rounded monitor-checkbox p-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="service_inactivity"
                      checked={input["service_inactivity"] as boolean}
                      onChange={handleInputOnChange}
                      id="service"
                    />
                    <label
                      className="form-check-label text-primary"
                      htmlFor="service"
                    >
                      Service Inactivity
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="presence_inactivity"
                      checked={input["presence_inactivity"] as boolean}
                      onChange={handleInputOnChange}
                      id="presence"
                    />
                    <label
                      className="form-check-label text-primary"
                      htmlFor="presence"
                    >
                      Presence Inactivity
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="mobile_presence_inactivity"
                      checked={input["mobile_presence_inactivity"] as boolean}
                      onChange={handleInputOnChange}
                      id="mobilePresence"
                    />
                    <label
                      className="form-check-label text-primary"
                      htmlFor="mobilePresence"
                    >
                      Mobile Presence Inactivity
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter>
          <form
            className="form"
            onSubmit={(event) => {
              submitMmailHandler(event);
            }}
          >
            <div className="d-flex flex-row align-items-center justify-content-end">
              {saveMessage.length > 0 && (
                <label
                  className={
                    "me-2 " +
                    (saveMessage.startsWith("Failed")
                      ? "text-danger"
                      : "text-success")
                  }
                >
                  {saveMessage}
                </label>
              )}
              <div>
                <button
                  className="btn btn-primary monitor-button"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </CardFooter>
      </Card>
    </>
  );
};

export default MonitorMail;
