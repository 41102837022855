import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ApplicationContext } from "../../context/ApplicationContext";

const AuthorizedRoute: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);

  if (
    applicationContext.webApi &&
    applicationContext.webApi.getCredentials() === null
  ) {
    return <Navigate to="/login" replace={true} />;
  }

  return <Outlet />;
};

export default AuthorizedRoute;
