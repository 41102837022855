import React from "react";

export interface DropDownLinkProps {
  className?: string;
  linkClassName?: string;
  style?: React.CSSProperties;
  to: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DropDownLink: React.FC<DropDownLinkProps> = (
  props: DropDownLinkProps
) => {
  let linkClassName =
    "dropdown-item" + (props.linkClassName ? " " + props.linkClassName : "");
  return (
    <li className={props.className} style={props.style}>
      <a className={linkClassName} href={props.to}>
        {props.children}
      </a>
    </li>
  );
};

export default DropDownLink;
