const ProvidersMock = {
    "total": 1,
    "page": 1,
    "limit": 10,
    "columns": [
      {
        "label": "Type",
        "field": "type",
        "width": "25%",
      },
      {
        "label": "Config",
        "field": "config",
        "width": "25%",
      },
      {
        "label": "Last Event",
        "field": "lastEvent",
        "width": "25%",
      },

    ],
    "rows": [
      {
        "id": 11,
        "type": "Spinco",
        "config": "651dcaa97b2bfbf50bcc3df0",
        "lastEvent": "2021-09-08",
        "dateCreated": "2021-09-08T09:45:07.763",
        "dateDeleted": null,
        "deleted": false,
        "customer": null,
      },
      {
        "id": 12,
        "type": "FAQ",
        "config": "651dcaa97b2bfbf50bcc3df0",
        "lastEvent": "2021-09-08",
        "dateCreated": "2021-09-08T09:45:07.763",
        "dateDeleted": null,
        "deleted": false,
        "customer": null,
      },
      {
        "id": 13,
        "type": "Alectoo",
        "config": "651dcaa97b2bfbf50bcc3df0",
        "lastEvent": "2021-09-08",
        "dateCreated": "2021-09-08T09:45:07.763",
        "dateDeleted": null,
        "deleted": false,
        "customer": null,
      },
      {
        "id": 14,
        "type": "Spinco App",
        "config": "651dcaa97b2bfbf50bcc3df0",
        "lastEvent": "2021-09-08",
        "dateCreated": "2021-09-08T09:45:07.763",
        "dateDeleted": null,
        "deleted": false,
        "customer": null,
      },
    ],
  };
  
  export default ProvidersMock;
  