import Icon from "./Icon";
import "./SideNavButton.css";
import "./SideNavLink.css";

export interface SideNavButtonProps {
  className?: string;
  image?: string;
  sidebarOpen?: boolean;
  tooltip?: string;
  fill?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const SideNavButton: React.FC<SideNavButtonProps> = (
  props: SideNavButtonProps
) => {
  return (
    <li className={"side-nav-button " + props.className}>
      <div className="side-nav-button-container">
        {props.tooltip && !props.sidebarOpen && (
          <span className="tooltip-text-button">{props.tooltip}</span>
        )}
        {props.tooltip && props.sidebarOpen && (
          <span
            className="tooltip-text-button-open"
            style={{ opacity: "100%" }}>
            {props.tooltip}
          </span>
        )}
        <button
          className="btn btn-sm hamburger-button border-0  w-100 text-start"
          type="button"
          onClick={props.onClick}>
          <Icon
            className="side-nav-link-icon me-2"
            name={props.image}
            fill={props.fill}
          />
        </button>
      </div>
    </li>
  );
};

export default SideNavButton;
