import React from "react";
import Container from "./Container";

export interface CardProps {
  className?: string;
  containerClassName?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Navbar: React.FC<CardProps> = (props: CardProps) => {
  return (
    <div
      className={"navbar" + (props.className ? " " + props.className : "")}
      style={props.style}
    >
      <Container className={props.containerClassName} fluid>
        {props.children}
      </Container>
    </div>
  );
};

export default Navbar;
