import React, {
  Dispatch,
  SetStateAction,
  FormEvent,
  useState,
  useContext,
} from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import { LoginState } from "./Login";
import ForgotPasswordMsg from "./ForgotPasswordMsg";
import "./ForgotPassword.css";

interface IFrogotPasswordProps {
  setLoginState?: Dispatch<SetStateAction<LoginState>>;
}
type ForgotPwdState = "msg" | "forgot";

const ForgotPassword: React.FC<IFrogotPasswordProps> = ({ setLoginState }) => {
  const applicationContext = useContext(ApplicationContext);
  const [formError, setFormError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [forgotPwdState, setForgotPwdState] = useState(
    "forgot" as ForgotPwdState
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(event);
    setErrorMessage("");
    if (event.currentTarget.reportValidity()) {
      applicationContext.webApi
        ?.forgotPassword(email)
        .then((response: any) => {
          console.log(response);
          console.log(email);
          setForgotPwdState("msg");
        })
        .catch((error) => {
          let errorMessage = "Unable to send e-mail! Error: " + error;
          console.log(error);
          if (error === 403) {
            errorMessage = "Non-existing user e-mail!";
          }
          setErrorMessage(errorMessage);
        });
    } else {
      setFormError(true);
    }
  };

  const renderError = () => {
    return (
      <div className="text-danger w-100">
        <ul>
          <li>{errorMessage}</li>
        </ul>
      </div>
    );
  };

  const renderForgotPassword = () => {
    return (
      <>
        <img
          src="./images/logo_login.png"
          className="img-logo mb-4 justify-content-center"
          alt="Alectoo brand logo"
        ></img>

        {errorMessage && renderError()}

        <p className="text-info">Instructions will be sent to your email!</p>
        <h2 className="text-primary mb-4">Enter email address</h2>
        <form
          className={
            "forgot-password-form needs-validation" + (formError === true ? " was-validated" : "")
          }
          onSubmit={handleSubmit}
          noValidate
        >
          <input
            type="email"
            name="email"
            id="email"
            className="form-control mb-2"
            placeholder="example@email.com"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            value={email}
            required
          />
          <span className="invalid-feedback mb-2">Email is required!</span>
          <button type="submit" className="btn btn-primary w-100 mb-2">
            Send
          </button>
          <button
            type="button"
            className="btn btn-secondary w-100"
            onClick={() => {
              if (setLoginState) setLoginState("login");
            }}
          >
            Cancel
          </button>
        </form>
      </>
    );
  };

  return (
    <div className="container container-mobile shadow-larger d-flex flex-column justify-content-center align-items-center">
      {(forgotPwdState === "forgot" && renderForgotPassword()) ||
        (forgotPwdState === "msg" && (
          <ForgotPasswordMsg email={email} setLoginState={setLoginState} />
        ))}
    </div>
  );
};

export default ForgotPassword;
