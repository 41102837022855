import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import CustomerBar from "./CustomerBar";
import Table, { TableData, TableInfo } from "./ui/Table";
import { ApplicationContext } from "../context/ApplicationContext";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import "./Groups.css";
import CardFooter from "./ui/CardFooter";

const Groups: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };

  type Option = {
    label: string;
    value: string;
  };

  const [data, setData] = useState<TableData>(initialData);
  const [mobileSelect2, setMobileSelect2] = useState<string>("Available");
  const [mobileSelect2Add, setMobileSelect2Add] = useState<string>("Available");
  const [mobileSelect3, setMobileSelect3] = useState<string>("Available");
  const [mobileSelect3Add, setMobileSelect3Add] = useState<string>("Available");
  const [samwinSelect2, setSamwinSelect2] = useState<string>("Available");
  const [samwinSelect2Add, setSamwinSelect2Add] = useState<string>("Available");
  const [samwinSelect3, setSamwinSelect3] = useState<string>("Available");
  const [samwinSelect3Add, setSamwinSelect3Add] = useState<string>("Available");
  const [isLoading, setIsLoading] = useState(false);
  const [addCollapse, setAddCollapse] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [getType, setType] = useState<number>();
  const [presenceStatus, setPresenceStatus] = useState("User Based Presence");
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [frmError, setFrmError] = useState(false);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );

  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const secondOption: Option[] = [
    { label: "Available", value: "Available" },
    { label: "Busy", value: "Busy" },
    { label: "Away", value: "Away" },
    { label: "DoNotDisturb", value: "DoNotDisturb" },
  ];

  const thirdOption: { [key: string]: Option[] } = {
    Available: [{ label: "Available", value: "Available" }],
    Busy: [
      { label: "InACall", value: "InACall" },
      { label: "InAConferenceCall", value: "InAConferenceCall" },
    ],
    DoNotDisturb: [{ label: "Presenting", value: "Presenting" }],
    Away: [{ label: "Away", value: "Away" }],
  };

  const generateGUID = () => {
    return crypto.randomUUID();
  };

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadDataForCustomer = useCallback(
    (customerId: number, currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getGroups(customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setTableInfo({ currentPage: currentPage, step: step, search: search });
      loadDataForCustomer(customerId, currentPage, step, search);
    },
    [customerId, loadDataForCustomer]
  );

  useEffect(() => {
    loadDataForCustomer(customerId, 1, tableInfo.step, tableInfo.search);
  }, [customerId, applicationContext.webApi]);

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    clearSearch();
  }, [applicationContext.customerId]);

  useEffect(() => {
    if (mobileSelect2) {
      const thirdOptions = thirdOption[mobileSelect2];
      if (thirdOptions && thirdOptions.length > 0) {
        setMobileSelect3(thirdOptions[0].value);
      }
    }

    if (mobileSelect2Add) {
      const thirdOptionsAdd = thirdOption[mobileSelect2Add];
      if (thirdOptionsAdd && thirdOptionsAdd.length > 0) {
        setMobileSelect3Add(thirdOptionsAdd[0].value);
      }
    }
  }, [mobileSelect2, mobileSelect2Add]);

  useEffect(() => {
    if (samwinSelect2) {
      const thirdOptions = thirdOption[samwinSelect2];
      if (thirdOptions && thirdOptions.length > 0) {
        setSamwinSelect3(thirdOptions[0].value);
      }
    }

    if (samwinSelect2Add) {
      const thirdOptionsAdd = thirdOption[samwinSelect2Add];
      if (thirdOptionsAdd && thirdOptionsAdd.length > 0) {
        setSamwinSelect3Add(thirdOptionsAdd[0].value);
      }
    }
  }, [samwinSelect2, samwinSelect2Add]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    console.log(customerId);
    console.log(data);
    clearSearch();
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
    const value = event.target.checked;
    let tempInput = Object.assign({}, input);
    tempInput = Object.assign(tempInput, { [event.target.id]: `${value}` });

    if (event.target.id.includes("presence_switch")) {
      if (value === true) {
        setPresenceStatus("Group Based Presence");
      } else {
        setPresenceStatus("User Based Presence");
      }
    }

    setInput(tempInput);
  };

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleClose = (index: number) => {
    let tempData = { ...data };
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const handleAddClose = () => {
    setAddCollapse(!addCollapse);
  };

  const handleDeleteYes = (index: number) => {
    index >= 0 ? handleClose(index) : handleAddClose();
    const groupId = data.rows[index].id;
    applicationContext.webApi?.removeGroup(groupId).then(() => {
      setTimeout(() => {
        loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
      }, 500);
      setDeleteConfirm(false);
    });
  };

  const handleDeleteNo = () => {
    setDeleteConfirm(false);
  };

  const handleDelete = () => {
    setDeleteConfirm(true);
  };

  const handleEdit = (index: number) => {
    const groupName = input["groupName"];
    //let groupMail = input["groupEmail"];
    let mobileToTeams_str = input["isMobileCheckboxChecked" + index];
    let mobileToTeams: boolean = mobileToTeams_str === "true" ? true : false;
    const mobileAvailability = mobileSelect2;
    const mobileActivity = mobileSelect3;
    let samwinToTeams_str = input["isSamwinCheckboxChecked" + index];
    let samwinToTeams: boolean = samwinToTeams_str === "true" ? true : false;
    const samwinAvailability = samwinSelect2;
    const samwinActivity = samwinSelect3;
    // let mailEnabled = false;
    // if (groupMail != "") {
    //   mailEnabled = true;
    // }

    applicationContext.webApi
      ?.saveGroup(customerId, {
        id: data.rows[index].id,
        displayName: groupName,
        //mail: groupMail,
        //mailEnabled: mailEnabled,
        mobileToTeams: mobileToTeams,
        mobileAvailability: mobileAvailability,
        mobileActivity: mobileActivity,
        samwinToTeams: samwinToTeams,
        samwinAvailability: samwinAvailability,
        samwinActivity: samwinActivity,
      })
      .then(() => {
        setTimeout(() => {
          loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
        }, 500);
      });
  };

  const handleAdd = () => {
    // let groupMail = input["groupEmailAdd"];
    // let mailEnabled = false;
    // if (groupMail != "") {
    //   mailEnabled = true;
    // }
    let mobileToTeams_str = input["isMobileCheckboxChecked" + "-1"];
    let mobileToTeams: boolean = mobileToTeams_str === "true" ? true : false;
    let samwinToTeams_str = input["isSamwinCheckboxChecked" + "-1"];
    let samwinToTeams: boolean = samwinToTeams_str === "true" ? true : false;

    applicationContext.webApi
      ?.saveGroup(customerId, {
        id: generateGUID(),
        CustomerId: customerId,
        displayName: input["groupNameAdd"],
        //mail: input["groupEmailAdd"],
        //mailEnabled: mailEnabled,
        mobileToTeams: mobileToTeams,
        mobileAvailability: mobileSelect2Add,
        mobileActivity: mobileSelect3Add,
        samwinToTeams: samwinToTeams,
        samwinAvailability: samwinSelect2Add,
        samwinActivity: samwinSelect3Add,
      })
      .then(() => {
        handleAddClose();
        setTimeout(() => {
          loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
        }, 500);
      });
  };

  const submitEditHandler = (
    event: React.FormEvent<HTMLFormElement>,
    index: number
  ) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      if (index >= 0) {
        handleEdit(index);
        setFrmError(false);
      } else {
        handleAdd();
      }
    } else {
      setFrmError(true);
    }
  };

  const sliderGroupUser = (): ReactElement => {
    return (
      <div className="d-flex flex-row align-items-center justify-content-center">
        <label className="me-2" htmlFor="presence_switch">
          <small className="text-primary"> {presenceStatus} </small>
        </label>
        <div className="form-check form-switch">
          <input
            type="checkbox"
            role="switch"
            className="form-check-input border-primary"
            id={"presence_switch"}
            name={"presence_switch"}
            checked={input["presence_switch"] === "true"}
            onChange={handleSwitchChange}
          ></input>
        </div>
      </div>
    );
  };

  const rowCustomRender = (i: number, j: number) => {
    let column = data.columns[j];
    let row: any = data.rows[i];
    if (column.field === "type") {
      let type: string = "";
      switch (row[column.field]) {
        case 0: {
          type = "Office 365";
          break;
        }
        case 1: {
          type = "Manual";
          break;
        }
        default:
          break;
      }
      return type;
    } else {
      return row[column.field] === null ? "" : row[column.field].toString();
    }
  };

  const renderForm = (index: number) => {
    return (
      <form
        className={"needs-validation p-1" + (frmError ? " was-validated" : "")}
        onSubmit={(event) => {
          submitEditHandler(event, index);
        }}
        noValidate
      >
        <Card className="border-0 shadow m-2">
          <CardBody className="bg-white bg-opacity-50 text-primary p-0 m-0">
            <div className="card bg-transparent border-0 d-flex flex-column m-4 align-items-start justify-content-center">
              <div className="card-header bg-transparent border pb-3 w-100 border-start-0 border-top-0 border-end-0">
                <div className="d-flex flex-md-row flex-column align-items-center justify-content-start">
                  <div className="pe-md-4 pe-0">
                    <label htmlFor={`groupName-label-${index}`}>
                      Group name:
                    </label>
                    <input
                      className="form-control form-control-md text-primary"
                      style={{ width: "300px" }}
                      type="text"
                      id={`groupName-${index}`}
                      name={index >= 0 ? "groupName" : "groupNameAdd"}
                      value={
                        index >= 0
                          ? input["groupName"] || ""
                          : input["groupNameAdd"] || ""
                      }
                      onChange={handleInputOnChange}
                      disabled={index >= 0}
                      required
                    />
                    <div className="invalid-tooltip">
                      Group Name is required!
                    </div>
                  </div>
                  {/* <div>
                    <label htmlFor={`groupEmail-${index}`}>Group e-mail:</label>
                    <input
                      className="form-control form-control-md text-primary"
                      style={{ width: "300px" }}
                      type="email"
                      id={`groupEmail-${index}`}
                      name={index >= 0 ? "groupEmail" : "groupEmailAdd"}
                      value={
                        index >= 0
                          ? input["groupEmail"] || ""
                          : input["groupEmailAdd"] || ""
                      }
                      onChange={handleInputOnChange}
                    />
                  </div> */}
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex flex-column flex-md-row"></div>
                <div className="flex-column flex-md-row form-check ms-md-4 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"isMobileCheckboxChecked" + index}
                    name={"isMobileCheckboxChecked" + index}
                    checked={
                      input["isMobileCheckboxChecked" + index] === "true"
                        ? true
                        : false
                    }
                    onChange={handleSwitchChange}
                  />
                  <label htmlFor="isMobileCheckboxChecked">
                    Mobile Presence in Teams
                  </label>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-center w-100">
                  <div className="d-flex flex-column m-2">
                    <label htmlFor={`mobileSelect2-${index}`}>
                      Mobile Availability:
                    </label>
                    <select
                      className="form-select form-select-sm text-primary groups-input-size"
                      id={`mobileSelect2-${index}`}
                      value={index >= 0 ? mobileSelect2 : mobileSelect2Add}
                      onChange={(event) =>
                        index >= 0
                          ? setMobileSelect2(event.target.value)
                          : setMobileSelect2Add(event.target.value)
                      }
                    >
                      {secondOption.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="d-flex flex-column m-2">
                    <label htmlFor="mobileSelect3">Mobile Activity:</label>
                    <select
                      className="form-select form-select-sm text-primary groups-input-size"
                      id={`mobileSelect3-${index}`}
                      value={index >= 0 ? mobileSelect3 : mobileSelect3Add}
                      onChange={(event) =>
                        index >= 0
                          ? setMobileSelect3(event.target.value)
                          : setMobileSelect3Add(event.target.value)
                      }
                    >
                      {(index >= 0
                        ? thirdOption[mobileSelect2]
                        : thirdOption[mobileSelect2Add]
                      ).map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex-column flex-md-row form-check ms-md-4 mb-2 mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"isSamwinCheckboxChecked" + index}
                    name={"isSamwinCheckboxChecked" + index}
                    checked={
                      input["isSamwinCheckboxChecked" + index] === "true"
                        ? true
                        : false
                    }
                    onChange={handleSwitchChange}
                  />
                  <label htmlFor="isSamwinCheckboxChecked">
                    Samwin Presence in Teams
                  </label>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-center w-100">
                  <div className="d-flex flex-column m-2">
                    <label htmlFor={`samwinSelect2-${index}`}>
                      Samwin Availability:
                    </label>
                    <select
                      className="form-select-sm text-primary groups-input-size"
                      id={`samwinSelect2-${index}`}
                      value={index >= 0 ? samwinSelect2 : samwinSelect2Add}
                      onChange={(event) =>
                        index >= 0
                          ? setSamwinSelect2(event.target.value)
                          : setSamwinSelect2Add(event.target.value)
                      }
                    >
                      {secondOption.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="d-flex flex-column m-2">
                    <label htmlFor={`samwinSelect3-${index}`}>
                      Samwin Activity:
                    </label>
                    <select
                      className="form-select-sm text-primary groups-input-size"
                      id={`samwinSelect3-${index}`}
                      value={index >= 0 ? samwinSelect3 : samwinSelect3Add}
                      onChange={(event) =>
                        index >= 0
                          ? setSamwinSelect3(event.target.value)
                          : setSamwinSelect3Add(event.target.value)
                      }
                    >
                      {(index >= 0
                        ? thirdOption[samwinSelect2]
                        : thirdOption[samwinSelect2Add]
                      ).map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter className="bg-white bg-opacity-25">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <div className={(index == -1 || getType == 0) ? "d-none" : ""}>
                  <div className={`${deleteConfirm ? "" : "d-none"}`}>
                    <span className="text-primary me-2">
                      Are you sure you want to remove?
                    </span>
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary me-3"
                      onClick={() => {
                        handleDeleteYes(index);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={handleDeleteNo}
                    >
                      No
                    </button>
                  </div>
                  <div style={deleteConfirm ? { display: "none" } : {}}>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger groupApp-edit-button me-2"
                      onClick={handleDelete}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="me-1 me-md-3 mb-2 mb-md-0">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary groupApp-edit-button"
                    onClick={() =>
                      index >= 0 ? handleClose(index) : handleAddClose()
                    }
                  >
                    Close
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-primary groupApp-edit-button"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </CardFooter>
        </Card>
      </form>
    );
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Groups" onCustomerChange={handleCustomerChange} />
      </div>
      <div>
        <Table
          className="table table-sm"
          data={data}
          loadData={loadData}
          tableInfo={tableInfo}
          setTableInfo={setTableInfo}
          responsive
          bordered
          isLoading={isLoading}
          addCollapse={addCollapse}
          renderHeader={sliderGroupUser}
          rowCustomRender={rowCustomRender}
          toggleAddCollapse={(): void => {
            let inputData = Object.assign({}, input);
            inputData["groupNameAdd"] = "";
            //inputData["groupEmailAdd"] = "";
            setAddCollapse(!addCollapse);
            setInput(inputData);
          }}
          toggleEditCollapse={(index: number) => {
            let tempData = Object.assign({}, data);
            let inputData = Object.assign({}, input);
            tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;

            inputData["groupName"] = tempData.rows[index].displayName;
            //inputData["groupEmail"] = tempData.rows[index].mail;
            inputData["isMobileCheckboxChecked" + index] =
              tempData.rows[index].mobileToTeams === true ? "true" : "false";
            inputData["mobileSelect2"] =
              tempData.rows[index].mobileAvailability;
            setMobileSelect2(tempData.rows[index].mobileAvailability);
            inputData["mobileSelect3"] = tempData.rows[index].mobileActivity;
            setMobileSelect3(tempData.rows[index].mobileActivity);
            inputData["isSamwinCheckboxChecked" + index] =
              tempData.rows[index].samwinToTeams === true ? "true" : "false";
            inputData["samwinSelect2"] =
              tempData.rows[index].samwinAvailability;
            setSamwinSelect2(tempData.rows[index].samwinAvailability);
            inputData["samwinSelect3"] = tempData.rows[index].samwinActivity;
            setSamwinSelect3(tempData.rows[index].samwinActivity);
            setType(tempData.rows[index].type);
            setInput(inputData);
            setData(tempData);
          }}
          renderAddCollapse={(): ReactElement => {
            return renderForm(-1);
          }}
          renderEditCollapse={(index: number): ReactElement => {
            return renderForm(index);
          }}
        />
      </div>
    </>
  );
};

export default Groups;
