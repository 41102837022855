import React, { ReactElement, useState } from "react";
import CardHeader from "./ui/CardHeader";
import CardBody from "./ui/CardBody";
import Card from "./ui/Card";
import CustomerBar from "./CustomerBar";
import CardFooter from "./ui/CardFooter";
import { ReactComponent as FilterIcon } from "../components/icons/filter.svg";
import Collapse from "./ui/Collapse";
export interface TableData {
  total: number;
  page: number;
  limit: number;
  rows: any[];
}

export interface MessagesProps {
  className?: string;
  style?: React.CSSProperties;
  bordered?: boolean;
}

type MessagesFiles = {
  serviceMessage: string;
  audioFile: string;
  description: string;
  fromTime: string;
  toTime: string;
};

const dataList: MessagesFiles[] = [
  {
    serviceMessage: " Service Message",
    audioFile: " Audio File Name",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
  {
    serviceMessage: " Service Message 2",
    audioFile: " Audio File Name",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
  {
    serviceMessage: " Service Message 3",
    audioFile: " Audio File Name",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
  {
    serviceMessage: " Service Message 4",
    audioFile: " Audio File Name",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
  {
    serviceMessage: " Service Message 5",
    audioFile: " Audio File Name",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
  {
    serviceMessage: " Service Message 6",
    audioFile: " Audio File Name",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
  {
    serviceMessage: " Service Message 7",
    audioFile: " Audio File Name",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
  {
    serviceMessage: " Service Message 8",
    audioFile: " Audio File Name",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
  {
    serviceMessage: " Service Message 9",
    audioFile: " Audio File Name",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
  {
    serviceMessage: " Service Message 10",
    audioFile: " Audio File Name ",
    description: " Description",
    fromTime: " 05-01-2024 11:25",
    toTime: " 07-08-2024 17:00",
  },
];

const Messages: React.FC<MessagesProps> = (props: MessagesProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [addCollapse, setAddCollapse] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  const toggleAddColapse = () => {
    setAddCollapse(!addCollapse);
  };

  // Function to handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Filtered data based on search term
  const filteredDataList = dataList.filter(
    (audioFile) =>
      audioFile.serviceMessage
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      audioFile.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to handle row click and toggle expansion
  const toggleEditCollapse = (index: number) => {
    if (expandedRowIndex === index) {
      setExpandedRowIndex(null); // Collapse if the same row is clicked
    } else {
      setExpandedRowIndex(index); // Expand the clicked row
    }
  };

  const renderMessagesTable = (): ReactElement => {
    let className: string;

    if (props.bordered) {
      className =
        (props?.className?.length ?? 0) > 0
          ? props.className + " table-bordered border-primary mb-0"
          : props.className + "table-bordered border-primary mb-0";
    } else {
      className = props.className ?? "table";
    }

    return (
      <>
        <div className="table-responsive border border-primary rounded">
          <table className={className} style={props.style}>
            <thead>
              <tr>
                <th
                  className="pt-1 pb-1 text-white fw-normal"
                  style={{ backgroundColor: "#747471" }}
                >
                  Service Messages
                </th>
                <th
                  className="pt-1 pb-1 text-white fw-normal"
                  style={{ backgroundColor: "#747471" }}
                >
                  Audio File
                </th>
                <th
                  className="pt-1 pb-1 text-white fw-normal"
                  style={{ backgroundColor: "#747471" }}
                >
                  Description
                </th>
                <th
                  className="pt-1 pb-1 text-white fw-normal"
                  style={{ backgroundColor: "#747471" }}
                >
                  From Time
                </th>
                <th
                  className="pt-1 pb-1 text-white fw-normal"
                  style={{ backgroundColor: "#747471" }}
                >
                  To Time
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredDataList.map((audioFile, index) => (
                <React.Fragment key={index}>
                  <tr
                    style={{
                      backgroundColor: index % 2 === 0 ? "#EEEEEE" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleEditCollapse(index)}
                  >
                    <td>{audioFile.serviceMessage}</td>
                    <td>{audioFile.audioFile}</td>
                    <td>{audioFile.description}</td>
                    <td>{audioFile.fromTime}</td>
                    <td>{audioFile.toTime}</td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan={7}>
                        <div className="p-3">
                          <div className="d-flex flex-column justify-content-md-between align-items-start py-3">
                            <div className="d-flex flex-row w-100 pb-2">
                              <div className="d-flex flex-column pe-1 w-100">
                                <label
                                  htmlFor="formControlInput"
                                  className="form-label"
                                >
                                  Service Message
                                </label>
                                <input
                                  className="form-control form-control-sm me-4"
                                  type="text"
                                  placeholder=""
                                ></input>
                              </div>
                              <div className="d-flex flex-column ps-1 w-100">
                                <label
                                  htmlFor="formControlInput"
                                  className="form-label"
                                >
                                  Audio File
                                </label>
                                <input
                                  className="form-control form-control-sm me-4"
                                  type="text"
                                  placeholder=""
                                ></input>
                              </div>
                            </div>
                            <div className="d-flex flex-row w-100 pb-2">
                              <div className="d-flex flex-column pe-1 w-100">
                                <label
                                  htmlFor="formControlInput"
                                  className="form-label"
                                >
                                  From Time
                                </label>
                                <input
                                  className="form-control form-control-sm me-4"
                                  type="text"
                                  placeholder=""
                                ></input>
                              </div>
                              <div className="d-flex flex-column ps-1 w-100">
                                <label
                                  htmlFor="formControlInput"
                                  className="form-label"
                                >
                                  To Time
                                </label>
                                <input
                                  className="form-control form-control-sm me-4"
                                  type="text"
                                  placeholder=""
                                ></input>
                              </div>
                            </div>
                            <div className="d-flex flex-row w-100 pb-2">
                              <div className="d-flex flex-column pe-1 w-100">
                                <label
                                  htmlFor="formControlInput"
                                  className="form-label"
                                >
                                  Description
                                </label>
                                <input
                                  className="form-control form-control-sm me-4"
                                  type="text"
                                  placeholder=""
                                ></input>
                              </div>
                            </div>
                            <div className="d-flex flex-row justify-content-between w-100 pt-3">
                              <div className="d-flex flex-column pe-1">
                                <button
                                  type="button"
                                  className="d-flex btn btn-sm btn-danger border border-primary justify-content-start align-items-center"
                                >
                                  Delete
                                </button>
                              </div>
                              <div className="d-flex flex-column ps-1 w-50">
                                <div className="d-flex flex-row justify-content-end">
                                  <div className="d-flex flex-column">
                                    <button
                                      type="button"
                                      className="d-flex btn btn-sm d border border-primary justify-content-start align-items-center"
                                      onClick={() => toggleEditCollapse(index)}
                                    >
                                      Close
                                    </button>
                                  </div>
                                  <div className="d-flex flex-column ms-2">
                                    <button
                                      type="button"
                                      className="d-flex btn btn-sm btn-success border border-primary justify-content-start align-items-center"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Mobile Users" />
      </div>

      <Card className="shadow mt-3 w-100">
        <CardHeader className="bg-white border border-primary border-bottom-0 rounded-top">
          <div className="d-flex flex-md-row flex-column justify-content-md-between align-items-start border-bottom pt-2 pb-3">
            <button
              type="button"
              className="btn btn-sm border border-primary me-3"
              style={{ backgroundColor: "#F8F8FF", width: "60px" }}
            >
              <FilterIcon />
            </button>
            <input
              className="form-control form-control-sm me-4"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            ></input>
            <button
              type="button"
              className="btn btn-secondary btn-sm w-25 border border-primary"
              onClick={toggleAddColapse}
            >
              Add new
            </button>
          </div>

          {/* Add new section */}
          <Collapse isExpanded={addCollapse}>
            <div className="d-flex flex-column justify-content-md-between align-items-start py-3">
              <div className="d-flex flex-row w-100 pb-2">
                <div className="d-flex flex-column pe-1 w-100">
                  <label htmlFor="formControlInput" className="form-label">
                    Service Message
                  </label>
                  <input
                    className="form-control form-control-sm me-4"
                    type="text"
                    placeholder=""
                  ></input>
                </div>
                <div className="d-flex flex-column ps-1 w-100">
                  <label htmlFor="formControlInput" className="form-label">
                    Audio File
                  </label>
                  <input
                    className="form-control form-control-sm me-4"
                    type="text"
                    placeholder=""
                  ></input>
                </div>
              </div>
              <div className="d-flex flex-row w-100 pb-2">
                <div className="d-flex flex-column pe-1 w-100">
                  <label htmlFor="formControlInput" className="form-label">
                    From Time
                  </label>
                  <input
                    className="form-control form-control-sm me-4"
                    type="text"
                    placeholder=""
                  ></input>
                </div>
                <div className="d-flex flex-column ps-1 w-100">
                  <label htmlFor="formControlInput" className="form-label">
                    To Time
                  </label>
                  <input
                    className="form-control form-control-sm me-4"
                    type="text"
                    placeholder=""
                  ></input>
                </div>
              </div>
              <div className="d-flex flex-row w-100 pb-2">
                <div className="d-flex flex-column pe-1 w-100">
                  <label htmlFor="formControlInput" className="form-label">
                    Description
                  </label>
                  <input
                    className="form-control form-control-sm me-4"
                    type="text"
                    placeholder=""
                  ></input>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between w-100 pt-3">
              <div className="d-flex flex-column pe-1">
                <button
                  type="button"
                  className="d-flex btn btn-sm btn-danger border border-primary justify-content-start align-items-center"
                >
                  Delete
                </button>
              </div>
              <div className="d-flex flex-column ps-1 w-50">
                <div className="d-flex flex-row justify-content-end">
                  <div className="d-flex flex-column">
                    <button
                      type="button"
                      className="d-flex btn btn-sm d border border-primary justify-content-start align-items-center"
                      onClick={toggleAddColapse}
                    >
                      Close
                    </button>
                  </div>
                  <div className="d-flex flex-column ms-2">
                    <button
                      type="button"
                      className="d-flex btn btn-sm btn-success border border-primary justify-content-start align-items-center"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>

          <div className="d-flex flex-md-row flex-column justify-content-md-between align-items-start pt-3">
            <div className="d-flex flex-row align-items-center me-3 mb-2">
              <small className="text-primary">Show&nbsp;</small>
              <select className="form-select form-select-sm text-primary">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <small className="text-primary">&nbsp;entries</small>
            </div>
            <div className="ps-0 mb-2">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <select
                    className="form-select form-select-sm text-primary"
                    name="groups"
                  >
                    <option value="-1">Group List</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="p-2 card-body border border-primary border-top-0 border-bottom-0">
          {renderMessagesTable()}
        </CardBody>
        <CardFooter className="d-flex flex-row justify-content-end bg-white border border-primary border-top-0 pt-2">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a
                  className="page-link border border-primary"
                  href="/"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item">
                <a
                  className="page-link border border-primary bg-secondary"
                  href="/"
                >
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link border border-primary" href="/">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link border border-primary" href="/">
                  3
                </a>
              </li>
              <li className="page-item">
                <a
                  className="page-link border border-primary"
                  href="/"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </CardFooter>
      </Card>
    </>
  );
};

export default Messages;
