import React from "react";
import { ApplicationApi } from "../services/web-api/application-api";
import { ApplicationRestApi } from "../services/web-api/rest/application-rest-api";

export interface ApplicationContextProps {
  webApi: ApplicationApi<ApplicationRestApi> | null;
  customerId: number;
  setCustomerId: React.Dispatch<React.SetStateAction<number>>;
  theme: number;
}

export const ApplicationContext = React.createContext<ApplicationContextProps>({
  webApi: null,
  customerId: -1,
  setCustomerId: () => {},
  theme: 1,
} as ApplicationContextProps);
