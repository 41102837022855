import React, { ReactElement, useState } from "react";
import CardHeader from "./ui/CardHeader";
import CardBody from "./ui/CardBody";
import Card from "./ui/Card";
import CustomerBar from "./CustomerBar";
import CardFooter from "./ui/CardFooter";
import Collapse from "./ui/Collapse";

export interface TableData {
  total: number;
  page: number;
  limit: number;
  rows: any[];
}

export interface GroupsJanssonProps {
  className?: string;
  style?: React.CSSProperties;
  bordered?: boolean;
}

type Person = {
  groupName: string;
  userName: string;
  mobileNumber: string;
  email: string;
  mobileGroupName: string;
  mobileGroupName2: string;
  mobileGroupNumber: string;
  phraseID: string;
  lockoutTime: string;
  noLockoutTime: string;
  activity: string;
  priority: string;
};

const dataList: Person[] = [
  {
    userName: "Lise Clemensen",
    email: "lise.lemensen@alectoo.com",
    mobileNumber: "454114114",
    groupName: "Group 1",
    mobileGroupName: "PLAN",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1100",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "No",
    activity: "",
    priority: "",
  },
  {
    userName: "Josef Carlsen",
    email: "josef.arlsen@alectoo.com",
    mobileNumber: "454114115",
    groupName: "Group 2",
    mobileGroupName: "TelKom",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1101",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "No",
    activity: "",
    priority: "",
  },
  {
    userName: "Paul Andresen",
    email: "paul.andresen@alectoo.com",
    mobileNumber: "454114116",
    groupName: "Group 3",
    mobileGroupName: "Formal",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1102",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "No",
    activity: "",
    priority: "",
  },
  {
    userName: "Ingolf Olesen",
    email: "ingolf.olesen@alectoo.com",
    mobileNumber: "454114117",
    groupName: "Group 4",
    mobileGroupName: "Overload",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1103",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "No",
    activity: "",
    priority: "",
  },
  {
    userName: "Dorthe Clemensen",
    email: "dorthe.clemensen@alectoo.com",
    mobileNumber: "454114118",
    groupName: "Group 5",
    mobileGroupName: "Posts",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1104",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "No",
    activity: "",
    priority: "",
  },
  {
    userName: "Gert Eliassen",
    email: "gert.eliassen@alectoo.com",
    mobileNumber: "454114119",
    groupName: "Group 6",
    mobileGroupName: "Daily",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1105",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "Yes",
    activity: "",
    priority: "",
  },
  {
    userName: "Inger Lang",
    email: "inger.lang@alectoo.com",
    mobileNumber: "454114120",
    groupName: "Group 7",
    mobileGroupName: "PLAN 2",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1106",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "No",
    activity: "",
    priority: "",
  },
  {
    userName: "Andrea Wolff",
    email: "andrea.wolff@alectoo.com",
    mobileNumber: "454114121",
    groupName: "Group 8",
    mobileGroupName: "News",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1107",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "No",
    activity: "",
    priority: "",
  },
  {
    userName: "Katja Madsen",
    email: "katja.madsen@alectoo.com",
    mobileNumber: "454114122",
    groupName: "Group 9",
    mobileGroupName: "Updates",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1108",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "No",
    activity: "",
    priority: "",
  },
  {
    userName: "Sandra Andreasen",
    email: "sandra.andreasen@alectoo.com",
    mobileNumber: "454114123",
    groupName: "Group 10",
    mobileGroupName: "Help",
    mobileGroupName2: "PLAN",
    mobileGroupNumber: "1109",
    phraseID: "",
    lockoutTime: "00:00:00",
    noLockoutTime: "No",
    activity: "",
    priority: "",
  },
];

const GroupsJansson: React.FC<GroupsJanssonProps> = (
  props: GroupsJanssonProps
) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [addCollapse, setAddCollapse] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  const toggleAddColapse = () => {
    setAddCollapse(!addCollapse);
  };

  // Function to handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Filtered data based on search term
  const filteredDataList = dataList.filter((person) =>
    person.groupName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to handle row click and toggle expansion
  const toggleEditCollapse = (index: number) => {
    if (expandedRowIndex === index) {
      setExpandedRowIndex(null); // Collapse if the same row is clicked
    } else {
      setExpandedRowIndex(index); // Expand the clicked row
    }
  };

  const renderGroupsJanssonTable = (): ReactElement => {
    let className: string;

    if (props.bordered) {
      className =
        (props?.className?.length ?? 0) > 0
          ? props.className + " table-bordered border-primary mb-0"
          : props.className + "table-bordered border-primary mb-0";
    } else {
      className = props.className ?? "table";
    }

    return (
      <>
        <div className="table-responsive border border-primary rounded">
          <table className={className} style={props.style}>
            <thead>
              <tr>
                <th
                  className="pt-1 pb-1 text-white fw-normal"
                  style={{ backgroundColor: "#747471" }}
                >
                  Group Name
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredDataList.map((person, index) => (
                <React.Fragment key={index}>
                  <tr
                    style={{
                      backgroundColor: index % 2 === 0 ? "#EEEEEE" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleEditCollapse(index)}
                  >
                    <td>{person.groupName}</td>
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td colSpan={1}>
                        <div className="d-flex flex-column justify-content-md-between align-items-start border-bottom py-3">
                          <div className="d-flex flex-row w-100 pb-2">
                            <div className="d-flex flex-column pe-1 w-100">
                              <label
                                htmlFor="formControlInput"
                                className="form-label"
                              >
                                Mobile Group Name
                              </label>
                              <input
                                className="form-control form-control-sm me-4"
                                type="text"
                                placeholder=""
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between w-100 pt-3">
                          <div className="d-flex flex-column pe-1">
                            <button
                              type="button"
                              className="d-flex btn btn-sm btn-danger border border-primary justify-content-start align-items-center"
                            >
                              Delete
                            </button>
                          </div>
                          <div className="d-flex flex-column ps-1 w-50">
                            <div className="d-flex flex-row justify-content-end">
                              <div className="d-flex flex-column">
                                <button
                                  type="button"
                                  className="d-flex btn btn-sm d border border-primary justify-content-start align-items-center"
                                  onClick={() => toggleEditCollapse(index)}
                                >
                                  Close
                                </button>
                              </div>
                              <div className="d-flex flex-column ms-2">
                                <button
                                  type="button"
                                  className="d-flex btn btn-sm btn-success border border-primary justify-content-start align-items-center"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Groups" />
      </div>

      <Card className="shadow mt-3 w-100">
        <CardHeader className="bg-white border border-primary border-bottom-0 rounded-top">
          <div className="d-flex flex-md-row flex-column justify-content-md-between align-items-start border-bottom pt-2 pb-3">
            <input
              className="form-control form-control-sm me-4"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            ></input>
            <button
              type="button"
              className="btn btn-secondary btn-sm w-25 border border-primary"
              onClick={toggleAddColapse}
            >
              Add new
            </button>
          </div>

          {/* Add new section */}
          <Collapse isExpanded={addCollapse}>
            <div className="d-flex flex-column justify-content-md-between align-items-start border-bottom py-3">
              <div className="d-flex flex-row w-100 pb-2">
                <div className="d-flex flex-column pe-1 w-100">
                  <label htmlFor="formControlInput" className="form-label">
                    Mobile Group Name
                  </label>
                  <input
                    className="form-control form-control-sm me-4"
                    type="text"
                    placeholder=""
                  ></input>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between w-100 pt-3">
              <div className="d-flex flex-column pe-1">
                <button
                  type="button"
                  className="d-flex btn btn-sm btn-danger border border-primary justify-content-start align-items-center"
                >
                  Delete
                </button>
              </div>
              <div className="d-flex flex-column ps-1 w-50">
                <div className="d-flex flex-row justify-content-end">
                  <div className="d-flex flex-column">
                    <button
                      type="button"
                      className="d-flex btn btn-sm d border border-primary justify-content-start align-items-center"
                      onClick={toggleAddColapse}
                    >
                      Close
                    </button>
                  </div>
                  <div className="d-flex flex-column ms-2">
                    <button
                      type="button"
                      className="d-flex btn btn-sm btn-success border border-primary justify-content-start align-items-center"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>

          <div className="d-flex flex-md-row flex-column justify-content-md-between align-items-start pt-3">
            <div className="d-flex flex-row align-items-center me-3 mb-2">
              <small className="text-primary">Show&nbsp;</small>
              <select className="form-select form-select-sm text-primary">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <small className="text-primary">&nbsp;entries</small>
            </div>
            <div className="ps-0 mb-2">
              <div className="d-flex flex-row align-items-center">
                <div>
                  <select
                    className="form-select form-select-sm text-primary"
                    name="groups"
                  >
                    <option value="-1">Group List</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="p-2 card-body border border-primary border-top-0 border-bottom-0">
          {renderGroupsJanssonTable()}
        </CardBody>
        <CardFooter className="d-flex flex-row justify-content-end bg-white border border-primary border-top-0 pt-2">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a
                  className="page-link border border-primary"
                  href="/"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item">
                <a
                  className="page-link border border-primary bg-secondary"
                  href="/"
                >
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link border border-primary" href="/">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link border border-primary" href="/">
                  3
                </a>
              </li>
              <li className="page-item">
                <a
                  className="page-link border border-primary"
                  href="/"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </CardFooter>
      </Card>
    </>
  );
};

export default GroupsJansson;
