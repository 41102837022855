import React, {
  KeyboardEvent,
  ChangeEvent,
  createRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { LoginState } from "./Login";
import { ApplicationContext } from "../context/ApplicationContext";
import { LoginResponse } from "../services/web-api/application-api";
import Spinner from "./ui/Spinner";
import "./Authentication.css";
import { ReactComponent as AuthIcon } from "./icons/AuthIcon.svg";

interface IAuthenticationProps {
  numberOfDigits: number;
  userName: string;
  password: string;
  rememberme: boolean;
  setLoginState?: React.Dispatch<React.SetStateAction<LoginState>>;
}

const Authentication: React.FC<IAuthenticationProps> = ({
  numberOfDigits,
  setLoginState,
  userName,
  password,
  rememberme,
}) => {
  const applicationContext = useContext(ApplicationContext);
  const navigate = useNavigate();

  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(true);

  const inputRefs: Array<React.RefObject<HTMLInputElement>> = Array.from<
    React.RefObject<HTMLInputElement>
  >({ length: numberOfDigits }).map<React.RefObject<HTMLInputElement>>(
    (): React.RefObject<HTMLInputElement> => {
      return createRef<HTMLInputElement>();
    }
  );

  useEffect(() => {
    inputRefs[0].current?.focus();
  }, []);

  useEffect(() => {
    const code = getAuthenticationCode();
    if (code.length === numberOfDigits) {
      setTimeout(() => {
        handleSubmit()
          .then(() => {
           console.log("success!");
          })
          .catch((error) => {
            console.log(error);
          });
      }, 3000);
    }
  }, [input]);
  

  const getAuthenticationCode = () => {
    let code = "";
    for (let i = 0; i < numberOfDigits; i++) {
      code += input["digit_" + i];
    }
    return code;
  };

  const handleSubmit = async () => {
    const code = getAuthenticationCode();
    setIsSubmitting(true);
    try {
      applicationContext.webApi
      ?.login(userName, password, code, rememberme)
      .then((loginResponse: LoginResponse) => {
        applicationContext.setCustomerId(loginResponse.customer);
        navigate("/");
        console.log(loginResponse);
      })
      .catch((error) => {
        console.log(error);
        if (typeof setLoginState == "function") {
          setLoginState("login");
        }
      });
    } catch (error) {
      console.log("Not being able to authenticate user.");
    } finally {
      setIsSubmitting(false);
    }
   
  };

  const onKeyDown = (event: KeyboardEvent, id: string) => {
    let keyCode = event.key.charCodeAt(0);

    if (keyCode === 66) {
      const index = parseInt(event.currentTarget.id.split("_")[1]);
      if (index > 0) {
        setInput({ ...input, ["digit_" + index]: "" });
        inputRefs[index - 1].current?.focus();
      } else if (index === 0) {
        setInput({ ...input, ["digit_" + index]: "" });
        inputRefs[index].current?.focus();
      }
    }

    if (keyCode > 47 && keyCode < 58) {
      const index = parseInt(event.currentTarget.id.split("_")[1]);
      if (index < numberOfDigits - 1) {
        inputRefs[index + 1].current?.focus();
      }
      setInput({ ...input, [id]: event.key });
      return;
    }

    event.preventDefault();
  };

  const renderInputs = () => {
    return inputRefs.map(
      (inputRef: React.RefObject<HTMLInputElement>, index) => {
        let key = "digit_" + index;
        return (
          <input
            key={key}
            id={"digit_" + index}
            type="text"
            pattern="[0-9]"
            maxLength={1}
            className="authentication-digit-input form-control"
            placeholder="_"
            onKeyDown={(event) => {
              onKeyDown(event, "digit_" + index);
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
            }}
            ref={inputRef}
            value={input["digit_" + index] || ""}
          />
        );
      }
    );
  };

  return (
    <div className="container container-mobile shadow-larger d-flex flex-column justify-content-center">
      <img
        src="./images/logo_login.png"
        className="img-logo mb-4"
        alt="Alectoo brand logo"
      />
      <div className="d-flex justify-content-center ">
        <div className="container container-desktop d-flex justify-content-center mb-5">
          <AuthIcon fill="#2B3467" />
        </div>
      </div>
      <div>
        <h2 className=" text-center text-primary mb-4 justify-content-center mb-3">
          Authentication
        </h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row mb-4">{renderInputs()}</div>
        <button type="submit" className="btn btn-primary w-100 mt-3 mb-3 p-2" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="ms-2">Submit</span>
      ) : (
        <>
         Submitting...
           <Spinner className="spinner-border-sm ms-3" /> 
        </>
      )}
        </button>
        <button
          type="button"
          className="btn btn-secondary w-100"
          onClick={() => {
            if (setLoginState) setLoginState("login");
          }}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default Authentication;
