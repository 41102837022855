import React from "react";

export interface CardFooterProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const CardFooter: React.FC<CardFooterProps> = (props: CardFooterProps) => {
  return (
    <div
      className={"card-footer" + (props.className ? " " + props.className : "")}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default CardFooter;
