import React, { useState, useEffect, useRef } from "react";
import "./DropDownSelect.css";
export interface Option {
  value: string | number;
  label: string;
}

export interface DropDownSelectProps {
  multiple?: boolean;
  selectedOption?: Option | Option[];
  options: Option[];
  onChange: (selected: Option[] | undefined) => void;
  placeHolder?: string;
  selectedSize?: number;
  className?: string;
}

const DropDownSelect: React.FC<DropDownSelectProps> = (
  props: DropDownSelectProps
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  let [updatedOptions, setUpdatedOptions] = useState<Option[]>(props.options);

  const clearOptions = () => {
    props.multiple ? props.onChange([]) : props.onChange(undefined);
  };

  function selectOption(option: Option) {
    if (props.multiple) {
      if (
        (props.selectedOption as Option[]).some((x) => x.value === option.value)
      ) {
        props.onChange(
          (props.selectedOption as Option[]).filter(
            (o) => o.value !== option.value
          )
        );
      } else {
        props.onChange([...(props.selectedOption as Option[]), option]);
      }
    } else if (option.value !== (props.selectedOption as Option).value) {
      props.onChange([option]);
    }
  }

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0);
  }, [isOpen]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.target !== containerRef.current) return;
      switch (e.code) {
        case "Enter":
        case "Space":
          setIsOpen((prev) => !prev);
          if (isOpen) selectOption(updatedOptions[highlightedIndex]);
          break;
        case "ArrowUp":
        case "ArrowDown": {
          if (!isOpen) {
            setIsOpen(true);
            break;
          }

          const newValue = highlightedIndex + (e.code === "ArrowDown" ? 1 : -1);
          if (newValue >= 0 && newValue < updatedOptions.length) {
            setHighlightedIndex(newValue);
          }
          break;
        }
        case "Escape":
          setIsOpen(false);
          break;
      }
    };
    if (containerRef.current)
      containerRef.current.addEventListener("keydown", handler);

    return () => {
      if (containerRef.current)
        containerRef.current.removeEventListener("keydown", handler);
    };
  }, [isOpen, highlightedIndex, props.options]);

  useEffect(() => {
    setUpdatedOptions(
      props.options.filter((option) =>
        props.multiple
          ? !(props.selectedOption as Option[]).some(
              (x) => x.value === option.value
            )
          : (option as Option).value !== (props.selectedOption as Option).value
      )
    );
  }, [props.selectedOption]);

  return (
    <div
      ref={containerRef}
      onBlur={() => setIsOpen(false)}
      onClick={() => setIsOpen((prev) => !prev)}
      tabIndex={0}
      className={`dds-container ${props.className || ""}`}
    >
      <span className="dds-value" style={{ fontSize: props.selectedSize }}>
        {props.multiple
          ? (props.selectedOption as Option[]).length > 0
            ? (props.selectedOption as Option[]).map((item) => {
                return (
                  <button
                    key={item.value}
                    className="dds-option_badge"
                    onClick={(e) => {
                      e.stopPropagation();
                      selectOption(item);
                    }}
                  >
                    {item.label}
                    <span className="dds-remove-btn"> &times;</span>
                  </button>
                );
              })
            : props.placeHolder
          : (props.selectedOption as Option).label
          ? (props.selectedOption as Option).label
          : props.placeHolder}
      </span>

      <button
        onClick={(e) => {
          e.stopPropagation();
          clearOptions();
        }}
        className="dds-clear-btn"
      >
        &times;
      </button>

      <div className="dds-divider"></div>
      <div
        className={`dds-caret ${
          !isOpen ? "dds-caret_open" : "dds-caret_close"
        }`}
      ></div>
      <ul className={`dds-options ${isOpen ? "dds-show" : ""}`}>
        {updatedOptions.map((option, index) => {
          return (
            <li
              key={option.value}
              className={`dds-option ${
                highlightedIndex === index ? "dds-highlighted" : ""
              }`}
              onClick={() => {
                selectOption(option);
              }}
              onMouseEnter={() => setHighlightedIndex(index)}
            >
              {option.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DropDownSelect;
