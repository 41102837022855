import React, { useState } from "react";
import Collapse from "./Collapse";
import Icon from "./Icon";
import "./SideNavCat.css";
import "./SideNav.css";
import HoverMenu from "./HoverMenu";

export interface SideNavCatProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  image?: string;
  name?: string;
  sidebarOpen?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const SideNavCat: React.FC<SideNavCatProps> = (props: SideNavCatProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  //const [isActive, setIsActive] = useState(false);

  return (
    <li
      className="link-of-navcat"
      onMouseEnter={() => {
        setIsHovered(true);
        if (props.onMouseEnter) props.onMouseEnter();
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        if (props.onMouseLeave) props.onMouseLeave();
      }}>
        
      {isHovered && !props.sidebarOpen && (
        <HoverMenu className="hover-component" name={props.name}>
          {props.children}
        </HoverMenu>
      )}
      <div
        className="side-nav-cat-container d-flex flex-row align-items-center ps-2 text-white pe-2"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsExpanded(!isExpanded);
        //  setIsActive(true);
        }}>
        <Icon
          className="side-nav-link-icon me-2"
          name={props.image}
          fill="white"
        />
        {props.sidebarOpen && (
          <>
            <small>{props.name}</small>
            <Icon
              className={`${isExpanded ? "chevron-up" : "chevron-down"} chevron-icon-fill `}
              style={{ height: "16px", width: "16px", marginLeft: "auto" }}
              name={isExpanded ? "chevronup" : "chevrondown"}></Icon>
          </>
        )}
      </div>
      {isExpanded && props.sidebarOpen && (
        <Collapse isExpanded={isExpanded}>
          <ul
            className="ps-0"
            >
            {props.children}
          </ul>
        </Collapse>
      )}
    </li>
  );
};

export default SideNavCat;
