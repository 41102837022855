import React, {
  useState,
  useContext,
  ReactElement,
  useEffect,
  useCallback,
} from "react";
import CustomerBar from "./CustomerBar";
import Table, { TableData, TableInfo } from "./ui/Table";
import { ApplicationContext } from "../context/ApplicationContext";

const AlectooApplication: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [usersInGroup, setUsersInGroup] = useState<any[]>([]);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadDataForCustomer = useCallback(
    (customerId: number, currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getHuntGroups(customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setTableInfo({ currentPage: currentPage, step: step, search: search });
      loadDataForCustomer(customerId, currentPage, step, search);
    },
    [customerId]
  );

  useEffect(() => {
    loadDataForCustomer(customerId, 1, tableInfo.step, tableInfo.search);
  }, [customerId]);

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    clearSearch();
  }, [applicationContext.customerId]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const index = parseInt(event.target.value, 10);
    setCustomerId(index);
    clearSearch();
  };

  const handleServerUpdateBtn = () => {
    console.log("Server Update Button Clicked!");
    // Call API here...
  };

  const isOnlineChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    userId: number,
    groupId: number,
    index: number
  ) => {
    const checked = event.target.checked;
    let tempInput: any[] = [...usersInGroup];
    tempInput[index].isOnline = checked;
    setUsersInGroup(tempInput);
    applicationContext.webApi?.updateUserIsOnline(groupId, userId, checked);
  };

  const renderSwitch = (
    index: number,
    userId: number,
    groupId: number,
    online: boolean,
    disabled: boolean
  ) => {
    const labelText = online ? "Online" : "Offline";
    return (
      <div className="form-check form-switch">
        <input
          type="checkbox"
          className="form-check-input"
          id={"custom_switch_group_" + groupId + "_" + index}
          disabled={disabled}
          onChange={(e) => {
            isOnlineChange(e, userId, groupId, index);
          }}
          checked={online}
        />
        <label
          className="form-check-label"
          htmlFor={"custom_switch_group_" + groupId + "_" + index}
        >
          {labelText}
        </label>
      </div>
    );
  };

  const renderGroupUsers = (index: number) => {
    if (usersInGroup.length > 0) {
      console.log("Index: ", index);
      return usersInGroup.map((user: any, i) => {
        let style =
          i % 2 !== 0 ? { backgroundColor: "rgb(0, 0, 0, 0.05)" } : {};
        return (
          <tr style={style} key={"group_" + index + "_" + user.userId}>
            <td
              className="pt-1 pb-1 ps-2 border border-primary border-start-0 border-end-0 border-bottom-0"
              style={{ fontSize: "0.9rem", fontWeight: 300 }}
            >
              {user.firstName}
            </td>
            <td
              className="pt-1 pb-1 ps-2 border border-primary border-end-0 border-bottom-0"
              style={{ fontSize: "0.9rem", fontWeight: 300 }}
            >
              {user.lastName}
            </td>
            <td
              className="pt-1 pb-1 ps-2 border border-primary border-end-0 border-bottom-0"
              style={{ fontSize: "0.9rem", fontWeight: 300 }}
            >
              {user.userName}
            </td>
            <td
              className="pt-1 pb-1 ps-2 border border-primary border-end-0 border-bottom-0"
              style={{ fontSize: "0.9rem", fontWeight: 300 }}
            >
              {renderSwitch(
                i,
                user.userId,
                data.rows[index].id,
                user.isOnline,
                false
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr id={"group_" + index}>
          <td colSpan={3} style={{ padding: "0" }}>
            <div
              className="d-flex flex-row justify-content-center border 
          border-primary border-top-0 border-bottom-0"
            >
              <small>There are no items to show...</small>
            </div>
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <div className="mb-3 mt-3">
        <CustomerBar
          name="Alectoo Application"
          buttonText="Server Update"
          onCustomerChange={handleCustomerChange}
          onButtonClick={handleServerUpdateBtn}
        />
      </div>
      <Table
        className="table table-sm mb-0"
        data={data}
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
        responsive
        bordered
        loadData={loadData}
        isLoading={isLoading}
        toggleEditCollapse={(index): void => {
          let tempData = Object.assign({}, data);
          tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
          applicationContext.webApi
            ?.getUsersInGroup(tempData.rows[index].id)
            .then((users) => {
              if (users != null) {
                setUsersInGroup(users);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          setData(tempData);
        }}
        renderEditCollapse={(index: number): ReactElement => {
          return (
            <>
              {
                <table className="table table-sm table-bordered mb-0">
                  <thead>
                    <tr>
                      <th
                        className="pt-1 pb-1 border border-primary border-start-0 border-top-0 border-end-0"
                        style={{
                          width: "30%",
                          padding: "8px",
                          fontSize: "0.9rem",
                          fontWeight: 400,
                        }}
                        key={"1" + index}
                      >
                        First Name
                      </th>
                      <th
                        className="pt-1 pb-1 border border-primary border-top-0 border-end-0"
                        style={{
                          width: "30%",
                          padding: "8px",
                          fontSize: "0.9rem",
                          fontWeight: 400,
                        }}
                        key={"2" + index}
                      >
                        Last Name
                      </th>
                      <th
                        className="pt-1 pb-1 border border-primary border-top-0 border-end-0"
                        style={{
                          width: "30%",
                          padding: "8px",
                          fontSize: "0.9rem",
                          fontWeight: 400,
                        }}
                        key={"3" + index}
                      >
                        User Name
                      </th>
                      <th
                        className="pt-1 pb-1 border border-primary border-top-0 border-end-0"
                        style={{
                          width: "10%",
                          padding: "8px",
                          fontSize: "0.9rem",
                          fontWeight: 400,
                        }}
                        key={"4" + index}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderGroupUsers(index)}</tbody>
                </table>
              }
            </>
          );
        }}
      />
    </>
  );
};

export default AlectooApplication;
