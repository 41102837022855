import React from "react";
import MediaQuery from "react-responsive";

const sizes = [576, 768, 992, 1200, 1400];

interface MediaQueryProps {
  children: React.ReactNode;
}

export const Mobile: React.FC<MediaQueryProps> = (
  props: MediaQueryProps
): React.JSX.Element => {
  return <MediaQuery maxWidth={sizes[0] - 1} {...props} />;
};

export const TabletMobile: React.FC<MediaQueryProps> = (
  props: MediaQueryProps
): React.JSX.Element => {
  return <MediaQuery maxWidth={sizes[1] - 1} {...props} />;
};

export const NotDesktop: React.FC<MediaQueryProps> = (
  props: MediaQueryProps
): React.JSX.Element => {
  return <MediaQuery maxWidth={sizes[2] - 1} {...props} />;
};

export const Tablet: React.FC<MediaQueryProps> = (
  props: MediaQueryProps
): React.JSX.Element => {
  return <MediaQuery minWidth={sizes[0]} maxWidth={sizes[1] - 1} {...props} />;
};

export const Desktop: React.FC<MediaQueryProps> = (
  props: MediaQueryProps
): React.JSX.Element => {
  return <MediaQuery minWidth={sizes[1]} {...props} />;
};

export const LargeDesktop: React.FC<MediaQueryProps> = (
  props: MediaQueryProps
): React.JSX.Element => {
  return <MediaQuery minWidth={sizes[2]} {...props} />;
};

export const ExtraLargeDesktop: React.FC<MediaQueryProps> = (
  props: MediaQueryProps
): React.JSX.Element => {
  return <MediaQuery minWidth={sizes[3]} {...props} />;
};

export const ExtraExtraLargeDesktop: React.FC<MediaQueryProps> = (
  props: MediaQueryProps
): React.JSX.Element => {
  return <MediaQuery minWidth={sizes[4]} {...props} />;
};
