import React from "react";
import Card from "./ui/Card";
import CardHeader from "./ui/CardHeader";
import CardBody from "./ui/CardBody";
import "./FAQ.css";
import { ReactComponent as External } from "./icons/ArrowUpRightFromSquare.svg";

const Faq: React.FC = () => {
  return (
    <div>
      <Card>
        <CardHeader className="d-flex flex-column flex-md-row justify-content-between pt-3">
          <div className="d-flex flex-column">
            <h1 className="main-header pb-2">FAQ</h1>
            <p className="main-paragraph">
              Quick answers to questions you may have. Can’t find what you are
              looking for?
              <br />
              Check our full documentation.
            </p>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-between">
            <button
              type="button"
              className="d-flex faq-btn btn btn-outline-dark align-items-center"
            >
              Documentation
              <div className="d-flex ps-2">
                <External
                  className="link-icon text-white"
                  style={{ height: "20px", width: "20px" }}
                />
              </div>
            </button>
          </div>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-column flex-lg-row w-100 justify-content-around">
            <div className="d-flex flex-column justify-content-between px-3">
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  How do I log in to the web app?
                </h4>
                <p className="paragraph my-0">
                  To log in to the web app, navigate to the login page and enter
                  your registered username or email address along with your
                  password. Ensure that you enter the correct credentials to
                  access your account successfully. If you encounter any issues,
                  double-check your input and make sure that your caps lock is
                  off.
                </p>
              </div>
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  How can I register for an account?
                </h4>
                <p className="paragraph my-0">
                  Registering for an account is quick and easy. Click on the
                  "Register" or "Sign Up" link on the homepage or login page.
                  You'll be directed to a registration form where you'll need to
                  provide essential details such as your name, email address,
                  password, and any other required information. After completing
                  the form, submit it, and you'll receive a verification email
                  to activate your account.
                </p>
              </div>
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  How do I update my profile information?
                </h4>
                <p className="paragraph my-0">
                  Updating your profile information is simple. Log in to your
                  account and go to the profile or settings section. Here, you
                  can edit various details such as your name, profile picture,
                  bio, and contact information. Make the desired changes, and
                  remember to save your updates before leaving the page.
                </p>
              </div>
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  What should I do if I encounter an error or issue while using
                  the web app?
                </h4>
                <p className="paragraph my-0">
                  If you experience any errors or issues while using the web
                  app, first try refreshing the page or clearing your browser
                  cache. If the problem persists, check the app's help or
                  support section for troubleshooting tips. You can also contact
                  customer support for assistance by submitting a support ticket
                  or reaching out via email or live chat.
                </p>
              </div>
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  Can I access the web app from multiple devices?
                </h4>
                <p className="paragraph my-0">
                  Yes, most web apps are designed to be accessible from multiple
                  devices, including desktop computers, laptops, tablets, and
                  smartphones. You can access the web app using any modern web
                  browser on your preferred device. Many web apps also offer
                  synchronization features, allowing you to seamlessly switch
                  between devices while maintaining your data and settings.
                </p>
              </div>
            </div>
            <div className="d-flex flex-column px-3">
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  What should I do if I forgot my password?
                </h4>
                <p className="paragraph my-0">
                  If you forgot your password, don't worry! Simply click on the
                  "Forgot Password" link on the login page. You'll be prompted
                  to enter your email address associated with your account.
                  After submitting your email, you'll receive instructions on
                  how to reset your password. Follow the provided steps, and
                  you'll regain access to your account in no time.
                </p>
              </div>
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  Can I change my email address associated with the account?
                </h4>
                <p className="paragraph my-0">
                  Yes, you can update your email address associated with your
                  account. To do so, log in to your account and navigate to the
                  account settings or profile page. Look for the option to edit
                  your email address and follow the prompts to make the change.
                  Once you've entered your new email address, you may need to
                  verify it to ensure the security of your account.
                </p>
              </div>
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  Where can I find my account settings?
                </h4>
                <p className="paragraph my-0">
                  Your account settings can typically be accessed by clicking on
                  your profile icon or username located at the top of the
                  screen. From there, select "Settings" or a similar option to
                  view and adjust your account preferences. Here, you can manage
                  various settings such as privacy controls, notification
                  preferences, and security settings.
                </p>
              </div>
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  How can I navigate to specific features or sections within the
                  app?
                </h4>
                <p className="paragraph my-0">
                  Navigation within the web app is typically straightforward.
                  Use the main menu, sidebar, or navigation bar to browse
                  through different sections or categories. You can also use
                  search functionality to quickly find specific features or
                  content. If you're unsure how to navigate to a particular
                  area, refer to the app's documentation or help resources for
                  guidance.
                </p>
              </div>
              <div className="d-flex flex-column py-2">
                <h4 className="faq-row-header fw-bolder pt-4 pb-2">
                  How do I log out of the web app?
                </h4>
                <p className="paragraph my-0">
                  To log out of the web app, locate the logout option typically
                  found in the account or profile menu. Click on the logout
                  button, and you'll be securely logged out of your account.
                  It's essential to log out, especially when using shared or
                  public devices, to protect your privacy and prevent
                  unauthorized access to your account.
                </p>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Faq;
