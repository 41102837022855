import React, {
  useState,
  useContext,
  ReactElement,
  useEffect,
  useCallback,
} from "react";
import Table, { TableData, TableInfo } from "./ui/Table";
import CustomerBar from "./CustomerBar";
import { ApplicationContext } from "../context/ApplicationContext";
import Card from "./ui/Card";
import CardBody from "./ui/CardBody";
import CardFooter from "./ui/CardFooter";
import "./Customers.css";

const Customers: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [addCollapse, setAddCollapse] = useState(false);
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [customerOf, setCustomerOf] = useState("0");
  const [customerOfAdd, setCustomerOfAdd] = useState("0");
  const [customers, setCustomers] = useState([]);
  const [frmError, setFrmError] = useState(false);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadDataForCustomer = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getCustomers(currentPage, step, search, false)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setTableInfo({ currentPage: currentPage, step: step, search: search });
      loadDataForCustomer(currentPage, step, search);
    },
    [loadDataForCustomer]
  );

  useEffect(() => {
    applicationContext.webApi
      ?.getCustomers(0, 0, "", false)
      .then((response) => {
        setCustomers(response.rows);
        setCustomerOf(response.rows.customerOfId);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
      clearSearch();
  }, [applicationContext.webApi]);

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleClose = (index: number) => {
    let tempData = Object.assign({}, data);
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const handleAddClose = () => {
    setAddCollapse(!addCollapse);
  };

  const submitEditHandler = (
    event: React.FormEvent<HTMLFormElement>,
    index: number
  ) => {
    event.preventDefault();
    if (event.currentTarget.reportValidity()) {
      if (index >= 0) {
        handleEditOnSave(index);
        setFrmError(false);
      } else {
        handleAdd();
      }
    } else {
      setFrmError(true);
    }
  };

  const handleAdd = () => {
    const name = input["nameAdd"];
    const address = input["addressAdd"];
    const city = input["cityAdd"];
    const zipCode = input["zipCodeAdd"];
    const country = input["countryAdd"];

    applicationContext.webApi
      ?.createCustomer({
        id: 0,
        name: name,
        address: address,
        city: city,
        zipCode: zipCode,
        country: country,
        customerOfId: parseInt(customerOfAdd, 10),
      })
      .then(() => {
        handleAddClose();
        setTimeout(() => {
          loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
        }, 500);
      });
  };

  const handleEditOnSave = (index: number) => {
    const name = input["name"];
    const address = input["address"];
    const city = input["city"];
    const zipCode = input["zipCode"];
    const country = input["country"];

    applicationContext.webApi
      ?.saveCustomer({
        id: data.rows[index].id,
        name: name,
        address: address,
        city: city,
        zipCode: zipCode,
        country: country,
        customerOfId: parseInt(customerOf, 10),
      })
      .then(() => {
        setTimeout(() => {
          loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
        }, 500);
      });
  };

  const handleonDeleteYes = (index: number) => {
    const customerId = data.rows[index].id;
    applicationContext.webApi?.deleteCustomer(customerId).then(() => {
      setTimeout(() => {
        loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
      }, 500);
      setDeleteConfirm(false);
    });
  };

  const handleOnDeleteNo = () => {
    setDeleteConfirm(false);
  };

  const handleOnDelete = () => {
    setDeleteConfirm(true);
  };

  const customerForm = (index: number) => {
    return (
      <form
        className={"needs-validation" + (frmError ? "was-validated" : "")}
        onSubmit={(event) => submitEditHandler(event, index)}
        noValidate
      >
        <Card className="bg-secondary bg-opacity-25 m-2">
          <CardBody>
            {/* 1 */}
            <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
                <label
                  className="form-label text-primary mb-0 ps-md-2 customer-label-size"
                  htmlFor={`name${index}`}
                  id={`name-label-${index}`}
                >
                  Name:
                </label>
                <input
                  className="form-control text-primary"
                  name={index >= 0 ? "name" : "nameAdd"}
                  id={`nameAdd-${index}`}
                  value={
                    index >= 0 ? input["name"] || "" : input["nameAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                 required/>
                 <div className="invalid-tooltip">
                  Name is required!
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="form-label text-primary ps-md-3 mb-0 customer-label-size"
                  htmlFor={`address-${index}`}
                  id={`address-label-${index}`}
                >
                  Address:
                </label>
                <input
                  className="form-control text-primary"
                  name={index >= 0 ? "address" : "addressAdd"}
                  id={`addressAdd-${index}`}
                  value={
                    index >= 0
                      ? input["address"] || ""
                      : input["addressAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                />
              </div>
            </div>
            {/* 2 */}
            <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="form-label text-primary ps-md-2 customer-label-size"
                  htmlFor={`city-${index}`}
                  id={`city-label-${index}`}
                >
                  City:
                </label>
                <input
                  className="form-control text-primary"
                  name={index >= 0 ? "city" : "cityAdd"}
                  id={`city-${index}`}
                  value={
                    index >= 0 ? input["city"] || "" : input["cityAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                />
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="form-label text-primary ps-md-3 mb-0 customer-label-size"
                  htmlFor={`zipCode-${index}`}
                  id={`zipCode-label-${index}`}
                >
                  Zip Code:
                </label>
                <input
                  className="form-control text-primary"
                  name={index >= 0 ? "zipCode" : "zipCodeAdd"}
                  id={`zipCode-${index}`}
                  value={
                    index >= 0
                      ? input["zipCode"] || ""
                      : input["zipCodeAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                ></input>
              </div>
            </div>
            {/* 3 */}
            <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="form-label text-primary mb-0 ps-md-2 customer-label-size"
                  htmlFor={`country-${index}`}
                  id={`country-label-${index}`}
                >
                  Country:
                </label>
                <input
                  className="form-control text-primary"
                  name={index >= 0 ? "country" : "countryAdd"}
                  id={`country-${index}`}
                  value={
                    index >= 0
                      ? input["country"] || ""
                      : input["countryAdd"] || ""
                  }
                  onChange={handleInputOnChange}
                />
              </div>
              <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2">
                <label
                  className="form-label text-primary ps-md-3 mb-0 customer-label-size"
                  htmlFor={`customer-${index}`}
                  id={`customer-label-${index}`}
                >
                  Customer of:
                </label>
                <select
                  id={`customer-${index}`}
                  className="form-select text-primary bg-white"
                  value={index >= 0 ? customerOf : customerOfAdd}
                  onChange={(event) =>
                    index >= 0
                      ? setCustomerOf(event.target.value)
                      : setCustomerOfAdd(event.target.value)
                  }
                >
                  <option key={"customer-0"} value="0">
                    None
                  </option>
                  {customers?.map((customer: any) => (
                    <option key={"customer-" + customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </CardBody>
          <CardFooter className="bg-secondary bg-opacity-25">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                <div className={`${deleteConfirm && index >= 0 ? "" : "d-none"}`}>
                  <span className="text-primary me-2">
                    Are you sure you want to delete the Customer?
                  </span>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary me-3"
                    onClick={() => {
                      handleonDeleteYes(index);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleOnDeleteNo}
                  >
                    No
                  </button>
                </div>
                <div style={deleteConfirm ? { display: "none" } : {}}>
                  <button
                    type="button"
                    className={"btn btn-sm btn-danger customer-edit-button" +
                    (index >= 0 ? "" : " d-none")}
                    onClick={handleOnDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary customer-edit-button m-1"
                  onClick={() =>
                    index >= 0 ? handleClose(index) : handleAddClose()
                  }
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-primary customer-edit-button m-1"
                >
                  Save
                </button>
              </div>
              </div>
          </CardFooter>
        </Card>
      </form>
    );
  };

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Customers" />
      </div>
      <Table
        className="table table-sm"
        data={data}
        tableInfo={tableInfo} 
        setTableInfo={setTableInfo}
        responsive
        bordered
        loadData={loadData}
        isLoading={isLoading}
        addCollapse={addCollapse}
        toggleAddCollapse={(): void => {
          let inputData = Object.assign({}, input);
          inputData["nameAdd"] = "";
          inputData["addressAdd"] = "";
          inputData["cityAdd"] = "";
          inputData["zipCodeAdd"] = "";
          inputData["countryAdd"] = "";
          inputData["customer"] = "0";
          setCustomerOfAdd("0");
          setDeleteConfirm(false);
          setAddCollapse(!addCollapse);
          setInput(inputData);
        }}
        toggleEditCollapse={(index: number): void => {
          let tempData = Object.assign({}, data);
          let inputData = Object.assign({}, input);
          tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
          inputData["name"] = tempData.rows[index].name;
          inputData["address"] = tempData.rows[index].address;
          inputData["city"] = tempData.rows[index].city;
          inputData["zipCode"] = tempData.rows[index].zipCode;
          inputData["country"] = tempData.rows[index].country;
          inputData["customer"] = tempData.rows[index].customerOfId;
          setCustomerOf(tempData.rows[index].customerOfId);
          setDeleteConfirm(false);
          setInput(inputData);
          setData(tempData);
        }}
        renderAddCollapse={(): ReactElement => {
          return customerForm(-1);
        }}
        renderEditCollapse={(index: number): ReactElement => {
          return customerForm(index);
        }}
      />
    </>
  );
};

export default Customers;
