import { ReactComponent as PlayIcon } from "./icons/Play.svg";
import { ReactComponent as PauseIcon } from "./icons/Pause.svg";
import { ReactComponent as StopIcon } from "./icons/Stop.svg";
import "./SamwinRecording.css";
import { useCallback, useState, useEffect } from "react";
import useSound from "use-sound";

interface Mp3PlayerProps {
  id: string;
  file: string;
}

const Mp3Player: React.FC<Mp3PlayerProps> = (props: Mp3PlayerProps) => {
  let interval: NodeJS.Timer;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const [play, { pause, stop, duration, sound }] = useSound(props.file, {
    id: props.id,
    interrupt: true,
    onend: () => {
      setIsPlaying(false);
      setCurrTime({ min: "00", sec: "00" });
      setPercentage(100);
    },
  });

  const [currTime, setCurrTime] = useState({
    min: "00",
    sec: "00",
  }); // current position of the audio in minutes and seconds

  const playingButton = () => {
    if (isPlaying && !isPaused) {
      setIsPaused(true);
      pause(); // this will pause the audio
      console.log("Paused:", props.id);
    } else if (isPlaying && isPaused) {
      setIsPaused(false);
      play(); // this will pause the audio
      console.log("Playing:", props.id);
    } else {
      setIsPaused(false);
      setIsPlaying(true);
      play(); // this will play the audio
    }
  };

  const resetElements = () => {
    const elements = document.querySelectorAll(".rectangles");
    elements.forEach((element) => {
      const htmlElement = element as HTMLElement;
      htmlElement.style.backgroundColor = "#676767"; // Reset to default color
    });
  };

  const stopButton = useCallback(() => {
    stop();
    setCurrTime({ min: "00", sec: "00" });
    setIsPlaying(false);
    setIsPaused(false);
    resetElements();
    setPercentage(0);
  }, [stop]);

  useEffect(() => {
    interval = setInterval(() => {
      if (isPlaying && sound.playing()) {
        const min = Math.floor(sound.seek() / 60);
        const sec = Math.floor(sound.seek() % 60);
        setCurrTime({
          min: min.toString().padStart(2, "0"),
          sec: sec.toString().padStart(2, "0"),
        });
        updateElements();
      }
    }, 500);

    return () => {
      console.log("sound not playing");
      clearInterval(interval);
    };
  }, [isPlaying]);

  const updateElements = () => {
    console.log("Checking data:", duration, sound.seek());

    if (!duration) {
      console.warn("Duration is not available.");
      return;
    }
    setPercentage((sound.seek() / (duration / 1000)) * 100);
  };

  const drawRectangles = () => {
    console.log(
      "Percentage: ",
      percentage,
      percentage >= 4 ? "#36454F" : "#676767"
    );

    const rectangles = [];
    const colors = ["#36454F", "#676767"];

    for (let i = 0; i <= 25; i++) {
      const colorIndex = percentage >= i * 4 ? 0 : 1;
      rectangles.push(
        <div
          key={`rectangle-${i}`}
          className={`rectangle-${i} rectangles`}
          style={{ backgroundColor: colors[colorIndex] }}></div>
      );
    }

    return <div className="msg pe-5">{rectangles}</div>;
  };

  return (
    <div className="d-flex flex-row justify-content-center">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-between frame">
          <div className="d-flex flex-column justify-content-start text-wrapper ps-3">
            Title of message
          </div>
          <div className="d-flex flex-column justify-content-end text-wrapper pe-3">
            {currTime.min}:{currTime.sec}
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="voice-msg">
            <div className="btns">
              {isPlaying && !isPaused ? (
                <PauseIcon
                  fill="#36454F"
                  style={{ height: "30px", width: "30px" }}
                  onClick={playingButton}
                />
              ) : (
                <PlayIcon
                  fill="#36454F"
                  style={{ height: "30px", width: "30px" }}
                  onClick={playingButton}
                />
              )}
              <StopIcon
                fill="#36454F"
                style={{ height: "30px", width: "30px" }}
                onClick={stopButton}
              />
            </div>
            {drawRectangles()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mp3Player;
