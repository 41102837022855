import React, { useState } from "react";
import Card from "./ui/Card";
import CardHeader from "./ui/CardHeader";
import CardBody from "./ui/CardBody";
import "./Manuals.css";
import { ReactComponent as External } from "./icons/ArrowUpRightFromSquare.svg";
import Icon from "./ui/Icon";
import Collapse from "./ui/Collapse";

const Manuals: React.FC = () => {
  const [expandedSection, setExpandedSection] = useState<number[]>([1]);

  const handleSectionClick = (sectionId: number) => {
    setExpandedSection((prevExpandedSection) => {
      if (prevExpandedSection.includes(sectionId)) {
        return prevExpandedSection.filter((id) => id !== sectionId);
      } else {
        return [...prevExpandedSection, sectionId];
      }
    });
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    sectionId: number
  ) => {
    if (event.key === "Enter" || event.key === " ") {
      handleSectionClick(sectionId);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader className="d-flex flex-column flex-md-row justify-content-between pt-3">
          <div className="d-flex flex-column">
            <h1 className="main-header pb-2">MANUALS</h1>
            <p className="main-paragraph">
              Explore comprehensive guides and documentation resources to
              enhance your experience with our web app.
            </p>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-between">
            <button
              type="button"
              className="d-flex manuals-btn btn btn-outline-dark align-items-center">
              Documentation
              <div className="d-flex ps-2">
                <External
                  className="link-icon"
                  style={{ height: "20px", width: "20px" }}
                />
              </div>
            </button>
          </div>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-column px-3">
            {/* 1 */}
            <div className="d-flex flex-column pb-3">
              <div
                className="d-flex flex-row justify-content-between align-items-center border-0 bg-transparent"
                onClick={() => {
                  handleSectionClick(1);
                }}
                onKeyDown={(event) => handleKeyDown(event, 1)}
                tabIndex={0}
                role="button"
                aria-expanded={expandedSection.includes(1)}>
                <h4 className="manuals-row-header fw-bolder pt-4 pb-1">
                  Getting Started
                </h4>
                <div className="d-flex ps-2">
                  <Icon
                    className={`${
                      expandedSection.includes(1)
                        ? "chevron-up"
                        : "chevron-down"
                    }`}
                    style={{
                      height: "16px",
                      width: "16px",
                      marginLeft: "auto",
                    }}
                    fill="dark"
                    name={
                      expandedSection.includes(1) ? "chevronup" : "chevrondown"
                    }></Icon>
                </div>
              </div>
              {expandedSection.includes(1) && (
                <Collapse isExpanded={true}>
                  <div className="d-flex flex-column my-3">
                    <h5 className="manuals-row-subheader py-2">
                      Getting Started with Our Web App
                    </h5>
                    <p className="paragraph my-0">
                      Welcome to our web app! This manual will guide you through
                      the basics of using our platform and help you get started
                      on your journey. Whether you're a new user or a seasoned
                      pro, this guide has everything you need to know to make
                      the most of our features and functionalities.
                    </p>
                  </div>
                  <div className="d-flex flex-column my-3">
                    <h5 className="manuals-row-subheader py-2">
                      Table of Contents:
                    </h5>
                    <ol className="my-0">
                      <li className="pb-1">Introduction</li>
                      <li className="pb-1">Creating an Account</li>
                      <li className="pb-1">Logging in</li>
                      <li className="pb-1">Navigating the Dashboard</li>
                      <li className="pb-1">Exploring Features</li>
                      <li className="pb-1">Customizing Settings</li>
                      <li className="pb-1">Getting Help and Support</li>
                    </ol>
                  </div>

                  <div className="d-flex flex-column my-3">
                    <h5 className="manuals-row-subheader py-2">Introduction</h5>
                    <p className="paragraph my-0">
                      Our web app is designed to simplify your tasks and
                      streamline your workflow. In this manual, we'll walk you
                      through the process of getting started with our platform,
                      from creating an account to navigating the dashboard and
                      exploring key features.
                    </p>
                  </div>
                  <div className="d-flex flex-column my-3">
                    <h5 className="manuals-row-subheader py-2">
                      Creating an Account
                    </h5>
                    <p className="paragraph my-0">
                      To begin using our web app, you'll need to create an
                      account. Follow the step-by-step instructions provided in
                      this section to sign up and create your account
                      successfully.
                    </p>
                  </div>
                  <div className="d-flex flex-column my-3">
                    <h5 className="manuals-row-subheader py-2">Logging In</h5>
                    <p className="paragraph my-0">
                      Once you've created an account, logging in is easy. Learn
                      how to access your account and log in securely to start
                      using our platform.
                    </p>
                  </div>
                </Collapse>
              )}
            </div>

            {/* 2 */}
            <div className="d-flex flex-column pb-3">
              <div
                className="d-flex flex-row justify-content-between align-items-center border-0 bg-transparent"
                onClick={() => {
                  handleSectionClick(2);
                }}
                onKeyDown={(event) => handleKeyDown(event, 2)}
                tabIndex={0}
                role="button"
                aria-expanded={expandedSection.includes(2)}>
                <h4 className="manuals-row-header fw-bolder pt-4 pb-1">
                  How can I register for an account?
                </h4>
                <div className="d-flex ps-2">
                  <Icon
                    className={`${
                      expandedSection ? "chevron-up" : "chevron-down"
                    }`}
                    style={{
                      height: "16px",
                      width: "16px",
                      marginLeft: "auto",
                    }}
                    fill="dark"
                    name={
                      expandedSection.includes(2) ? "chevronup" : "chevrondown"
                    }></Icon>
                </div>
              </div>
              {expandedSection.includes(2) && (
                <Collapse isExpanded={true}>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Incidunt accusantium quia earum doloribus atque eveniet
                    maxime in! Maiores laudantium, adipisci, modi, facere minima
                    ab tempora aperiam et voluptatum voluptas accusantium!
                  </p>
                </Collapse>
              )}
            </div>
            {/* 3 */}
            <div className="d-flex flex-column pb-3">
              <div
                className="d-flex flex-row justify-content-between align-items-center border-0 bg-transparent"
                onClick={() => {
                  handleSectionClick(3);
                }}
                onKeyDown={(event) => handleKeyDown(event, 3)}
                tabIndex={0}
                role="button"
                aria-expanded={expandedSection.includes(3)}>
                <h4 className="manuals-row-header fw-bolder pt-4 pb-1">
                  How to update my profile information?
                </h4>
                <div className="d-flex ps-2">
                  <Icon
                    className={`${
                      expandedSection.includes(3)
                        ? "chevron-up"
                        : "chevron-down"
                    }`}
                    style={{
                      height: "16px",
                      width: "16px",
                      marginLeft: "auto",
                    }}
                    fill="dark"
                    name={
                      expandedSection.includes(3) ? "chevronup" : "chevrondown"
                    }></Icon>
                </div>
              </div>
              {expandedSection.includes(3) && (
                <Collapse isExpanded={true}>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Incidunt accusantium quia earum doloribus atque eveniet
                    maxime in! Maiores laudantium, adipisci, modi, facere minima
                    ab tempora aperiam et voluptatum voluptas accusantium!
                  </p>
                </Collapse>
              )}
            </div>
            {/* 4 */}
            <div className="d-flex flex-column pb-3">
              <div
                className="d-flex flex-row justify-content-between align-items-center border-0 bg-transparent"
                onClick={() => {
                  handleSectionClick(4);
                }}
                onKeyDown={(event) => handleKeyDown(event, 4)}
                tabIndex={0}
                role="button"
                aria-expanded={expandedSection.includes(4)}>
                <h4 className="manuals-row-header fw-bolder pt-4 pb-1">
                  What should I do if I encopunter an error or issue while using
                  the web app?
                </h4>
                <div className="d-flex ps-2">
                  <Icon
                    className={`${
                      expandedSection.includes(4)
                        ? "chevron-up"
                        : "chevron-down"
                    }`}
                    style={{
                      height: "16px",
                      width: "16px",
                      marginLeft: "auto",
                    }}
                    fill="dark"
                    name={
                      expandedSection.includes(4) ? "chevronup" : "chevrondown"
                    }></Icon>
                </div>
              </div>
              {expandedSection.includes(4) && (
                <Collapse isExpanded={true}>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Incidunt accusantium quia earum doloribus atque eveniet
                    maxime in! Maiores laudantium, adipisci, modi, facere minima
                    ab tempora aperiam et voluptatum voluptas accusantium!
                  </p>
                </Collapse>
              )}
            </div>
            {/* 5 */}
            <div className="d-flex flex-column pb-3">
              <div
                className="d-flex flex-row justify-content-between align-items-center border-0 bg-transparent"
                onClick={() => {
                  handleSectionClick(5);
                }}
                onKeyDown={(event) => handleKeyDown(event, 5)}
                tabIndex={0}
                role="button"
                aria-expanded={expandedSection.includes(5)}>
                <h4 className="manuals-row-header fw-bolder pt-4 pb-1">
                  Can I access the web app from multiple devices?
                </h4>
                <div className="d-flex ps-2">
                  <Icon
                    className={`${
                      expandedSection.includes(5)
                        ? "chevron-up"
                        : "chevron-down"
                    }`}
                    style={{
                      height: "16px",
                      width: "16px",
                      marginLeft: "auto",
                    }}
                    fill="dark"
                    name={
                      expandedSection.includes(5) ? "chevronup" : "chevrondown"
                    }></Icon>
                </div>
              </div>
              {expandedSection.includes(5) && (
                <Collapse isExpanded={true}>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Incidunt accusantium quia earum doloribus atque eveniet
                    maxime in! Maiores laudantium, adipisci, modi, facere minima
                    ab tempora aperiam et voluptatum voluptas accusantium!
                  </p>
                </Collapse>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Manuals;
