import React from "react";

export interface AudioProgressBarProps
  extends React.ComponentPropsWithoutRef<"input"> {
  duration: number;
  currentProgress: number;
  buffered: number;
}

interface ProgressCSSProps extends React.CSSProperties {
  "--progress-width": number;
  "--buffered-width": number;
}

const AudioProgressBar: React.FC<AudioProgressBarProps> = (
  props: AudioProgressBarProps
) => {
  const { duration, currentProgress, buffered, ...rest } = props;

  const progressBarWidth = isNaN(currentProgress / duration)
    ? 0
    : currentProgress / duration;
  const bufferedWidth = isNaN(buffered / duration) ? 0 : buffered / duration;

  const progressStyles: ProgressCSSProps = {
    "--progress-width": progressBarWidth,
    "--buffered-width": bufferedWidth,
  };
  return (
    <div className="d-flex">
      <input
        type="range"
        name="progress"
        className="progress"
        min={0}
        max={duration}
        style={progressStyles}
        value={currentProgress}
        {...rest}
      />
    </div>
  );
};

export default AudioProgressBar;
