import React from "react";
import "./SideNav.css";
import { Link } from "react-router-dom";

export interface SideNavProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  logo?: string;
  logoLink?: string;
  logoHeight?: string;
}

const SideNav: React.FC<SideNavProps> = (props: SideNavProps) => {
  return (
    <div
      className={"vh-100" + (props.className ? " " + props.className : "")}
      style={props.style}>
      <Link
        to={props.logoLink ?? ""}
        className="side-nav-logo d-flex justify-content-center align-items-center">
        <img
          className="side-nav-image"
          src={props.logo}
          alt="Logo"
          style={{ height: props.logoHeight }}
        />
      </Link>
      <ul className="side-nav-list ps-0 pt-0">{props.children}</ul>
    </div>
  );
};

export default SideNav;
