import React, {
    useState,
    useEffect,
    useContext,
    ReactElement
  } from "react";
  import CustomerBar from "./CustomerBar";
  import Card from "./ui/Card";
  import CardBody from "./ui/CardBody";
  import CardFooter from "./ui/CardFooter";
  import Table, { TableData, TableInfo } from "./ui/Table";
  import { ApplicationContext } from "../context/ApplicationContext";
  import ProvidersMock from "./ProvidersMock";
  import "./GroupProviders.css";

  const GroupProviders: React.FC = () => {
    const applicationContext = useContext(ApplicationContext);
    const initialData: TableData = {
      total: 0,
      page: 1,
      limit: 10,
      columns: [],
      rows: [],
    };
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [data, setData] = useState<TableData>(initialData);
    const [addCollapse, setAddCollapse] = useState(false);
    const [frmError, setFrmError] = useState(false);
    const [input, setInput] = useState<{ [key: string]: string }>({});
    const [customerId, setCustomerId] = useState<number>(
      applicationContext.webApi?.isSuperAdmin()
        ? applicationContext.customerId
        : applicationContext.webApi?.getCredentials()?.customer ?? -1
    );

    const [tableInfo, setTableInfo] = useState<TableInfo>({
      currentPage: 1,
      step: 10,
      search: "",
    });

    const clearSearch = () => {
      setTableInfo({ ...tableInfo, search: "" });
    };

    useEffect(() => {
        setCustomerId(applicationContext.customerId);
        setData(ProvidersMock);
        setIsLoading(false);
        clearSearch();
      }, [applicationContext.customerId]);
    
      const handleInputOnChange = (event: any) => {
        setInput({ ...input, [event.target.name]: event.target.value });
      };

      const handleCustomerChange = (
        event: React.ChangeEvent<HTMLSelectElement>
      ) => {
        const id = parseInt(event.target.value, 10);
        setCustomerId(id);
        console.log(customerId);
        console.log(data);
        clearSearch();
      };

      const submitEditHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (event.currentTarget.reportValidity()) {
            console.log("added");
        } else {
          setFrmError(true);
        }
      };
    
      const handleClose = (index: number) => {
        let tempData = { ...data};
        tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
        setData(tempData);
      };
    
      const handleAddClose = () => {
        setAddCollapse(!addCollapse);
      };

      const handleDeleteYes = (index: number) => {
        index >= 0 ? handleClose(index) : handleAddClose();
          setDeleteConfirm(false);
      };
    
      const handleDeleteNo = () => {
        setDeleteConfirm(false);
      };
      const handleDelete = () => {
        setDeleteConfirm(true);
      };
    
      const renderForm = (index: number) => {
        return (
          <form
            className={"needs-validation" + (frmError ? " was-validated" : "")}
            onSubmit={(event) => {
              submitEditHandler(event);
            }}
            noValidate
          >
            <Card className="bg-secondary bg-opacity-25 m-2">
              <CardBody>
              <div className="d-flex flex-column align-items-center mb-2">
              <div className="d-flex flex-column flex-md-row align-items-left w-100 pe-2 mb-3">
              <div className="d-flex flex-row pe-3">
                    <label
                      className="form-label text-primary ps-md-3 providers-label-size"
                      htmlFor={`type`}>
                      Type:
                    </label>
                    </div>
                    <div className="d-flex flex-row w-md-50 providers-input">
                    <input
                  className="form-control text-primary"
                  name={index >=0 ? "type" : "typeAdd"}
                  id={`type-${index}`}
                  value={index >= 0 ? input["type"] || "" : input["typeAdd"] || ""}
                  onChange={handleInputOnChange}
                />
                </div>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-left w-100 pe-2">
                <div className="pe-3 d-flex flex-row">
                    <label
                      className="form-label text-primary ps-md-3"
                      htmlFor={`config`}>
                      Config:
                    </label>
                    </div>
                    <div className="d-flex flex-row w-md-50 providers-input">
                    <textarea
                    className="form-control text-primary"
                    id={`config-${index}`}
                    name={index >= 0 ? "config" : "configAdd"}
                    value={index >= 0 ? input["config"] || "" : input["configAdd"]}
                    onChange={handleInputOnChange}
                    rows={4}
                    cols={20}
                  />
                 </div>
                 </div>
                 </div>
              </CardBody>
              <CardFooter className="bg-secondary bg-opacity-25">
              <div className="d-flex flex-row justify-content-between">
            <div>
              <div className={index >= 0 ? "" : "d-none"}>
                <div className={`${deleteConfirm ? "" : "d-none"}`}>
                  <span className="text-primary me-2">
                    Are you sure you want to remove?
                  </span>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary me-3"
                    onClick={() => {
                      handleDeleteYes(index);
                    }}>
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleDeleteNo}>
                    No
                  </button>
                </div>
                <div style={deleteConfirm ? { display: "none" } : {}}>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger phone-range-edit-button me-2"
                    onClick={handleDelete}>
                    Remove
                  </button>
                </div>
              </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="me-1 me-md-3 mb-2 mb-md-0">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary phone-range-edit-button"
                  onClick={() =>
                    index >= 0 ? handleClose(index) : handleAddClose()
                  }>
                  Close
                </button>
                </div>
                <div>
                <button
                  className="btn btn-sm btn-primary phone-range-edit-button"
                  type="submit">
                  Save
                </button>
                </div>
              </div>
            </div>
              </CardFooter>
              </Card>
      </form>
    );
  };

return (
<>
<div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Providers" onCustomerChange={handleCustomerChange} />
      </div>
      <div>
        <Table
          className="table table-sm"
          data={data}
          tableInfo={tableInfo} 
        setTableInfo={setTableInfo}
          responsive
          bordered
          isLoading={isLoading}
          addCollapse={addCollapse}
          toggleAddCollapse={(): void => {
            let inputData = {...input};
            inputData["typeAdd"] = "";
            inputData["configAdd"] = "";
            setInput(inputData);
              setAddCollapse(!addCollapse);
            }}
         toggleEditCollapse={(index: number): void => {
            let tempData = {...data};
            let inputData = {...input};
            tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
            inputData["type"] = tempData.rows[index].type;
            inputData["config"] = tempData.rows[index].config;
            setInput(inputData);
            setData(tempData);
          }}
          renderAddCollapse={(): ReactElement => {
          return renderForm(-1);
          }}
          renderEditCollapse={(index: number): ReactElement => {
            return renderForm(index);
          }}
        />
      </div>
</>
);
};

export default GroupProviders;