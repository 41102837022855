import React, {
  FormEvent,
  useContext,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import "./ChangePassword.css";
import { ApplicationContext } from "../context/ApplicationContext";
import { useNavigate } from "react-router-dom";

const ChangePassword: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const navigate = useNavigate();
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [token, setToken] = useState<string>("");
  const [formError, setFormError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const queryString = window.location.href;
    const url = new URL(queryString);
    const params = new URLSearchParams(url.search);
    const token = params.get("token");
    console.log(token);
    setToken(token ?? "");
  }, []);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.name, event.target.value);
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(input["password"]);
    setErrorMessage("");
    if (event.currentTarget.reportValidity()) {
      if (input["password"] !== input["changePassword"]) {
        setErrorMessage("Passwords do not match!");
      } else {
        applicationContext.webApi
          ?.changePassword(token, input["password"] ?? "")
          .then((response: any) => {
            console.log(response);
            navigate("/");
          })
          .catch((error) => {
            let errorMessage = "Unable to Change Password! Error: " + error;

            if (error === 400) {
              errorMessage = "Bad Request";
            }
            console.log(error);

            setErrorMessage(errorMessage);
          });
      }
    } else {
      setFormError(true);
    }
  };

  const renderError = () => {
    return (
      <div className="text-danger w-100">
        <ul>
          <li>{errorMessage}</li>
        </ul>
      </div>
    );
  };

  const renderChangePWd = () => {
    return (
      <div className="main-container d-flex justify-content-center align-items-center w-100 h-100 position-fixed">
        <div className=" shadow-larger d-flex flex-column justify-content-center align-items-center">
          <img
            src="./images/logo_login.png"
            className="img-logo mb-5"
            alt="Alectoo brand logo"
          />
          {errorMessage && renderError()}
          <div className="d-flex justify-content-start flex-column align-items-start">
            <h2 className="text-changePwd text-primary mb-4 ">New Password</h2>
            <form
              style={{ width: 450 }}
              className={
                "change-password-form needs-validation" +
                (formError === true ? " was-validated" : "")
              }
              onSubmit={handleSubmit}
              noValidate
            >
              <input
                type="password"
                className="form-control mb-3"
                placeholder="Enter new password"
                name="password"
                value={input["password"] ?? ""}
                onChange={handleOnChange}
                required
              />
              <span className="invalid-feedback mb-2">
                Password is required!
              </span>
              <input
                type="password"
                className="form-control mb-3 "
                placeholder="Repeat Password"
                name="changePassword"
                value={input["changePassword"] ?? ""}
                onChange={handleOnChange}
                required
              />
              <span className="invalid-feedback mb-2">
                Repeat Password is required!
              </span>
              <div>
                <a className="descTxt text-primary underline " role="button">
                  Already registered? Proceed to Login
                </a>
                <a className="mobileTxt text-primary underline " role="button">
                  Already registered?
                </a>
              </div>
              <button type="submit" className="btn btn-primary w-100 mb-4 mt-4">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return <div className="d-flex bg-white m-auto">{renderChangePWd()}</div>;
};

export default ChangePassword;
