import React, { useState, useContext, useEffect, useCallback } from "react";
import "./Dashboard.css";
import CardBody from "./ui/CardBody";
import CardHeader from "./ui/CardHeader";
import Card from "./ui/Card";
import Table, { TableData, TableInfo } from "./ui/Table";
import { ApplicationContext } from "../context/ApplicationContext";
import CustomerBar from "./CustomerBar";
import Icon from "./ui/Icon";
import moment from "moment";

const Dashboard: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.customerId
  );
  const [color, setColor] = useState("red");
  const [serviceActivity, setServiceActivity] = useState("");
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const clearSearch = () => {
    setTableInfo({ ...tableInfo, search: "" });
  };

  const loadDataForCustomer = useCallback(
    (customerId: number, currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getMobileUsers(customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });

      applicationContext.webApi?.getCustomersOf(customerId).then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          if (data[0].serviceActivity && data[0].serviceActivity.length > 0) {
            const activity: Date = new Date(data[0].serviceActivity + "Z");
            const difference: number =
              new Date().getTime() - activity.getTime();
            const diffMinutes = Math.floor(difference / 1000 / 60);

            setServiceActivity(data[0].serviceActivity + "Z");

            if (diffMinutes < 7) {
              setColor("green");
            } else {
              setColor("red");
            }
          } else {
            setColor("red");
            setServiceActivity("");
          }
        }
      });
    },
    [customerId]
  );

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setTableInfo({ currentPage: currentPage, step: step, search: search });
      loadDataForCustomer(customerId, currentPage, step, search);
    },
    [customerId]
  );

  useEffect(() => {
    loadDataForCustomer(customerId, 1, tableInfo.step, tableInfo.search);
  }, [customerId]);

  const handleCustomerChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = parseInt(event.target.value, 10);
    setCustomerId(id);
    clearSearch();
  };

  useEffect(() => {
    setCustomerId(applicationContext.customerId);
    clearSearch();
  }, [applicationContext.customerId]);

  return (
    <>
      <div className="mb-3 mt-3 pl-3">
        <CustomerBar name="Dashboard" onCustomerChange={handleCustomerChange} />
      </div>
      <Card className="border border-primary shadow rounded">
        <CardHeader className="bg-transparent border-bottom-0">
          <h4 className="text-primary">Alectoo Service</h4>
        </CardHeader>
        <CardBody className="border border-primary border-start-0 border-end-0 border-bottom-0">
          <div>
            <div className="second d-flex justify-content-start">
              <Icon className="dashboard-icon" fill={color} name="services" />
              <div className="d-flex flex-column text-primary justify-content-center w-70 ms-3 flex-grow-1">
                <a>Service is {color === "green" ? "" : "not"} running</a>
                <a>
                  Last connection{" "}
                  {serviceActivity.length > 0
                    ? moment(new Date(serviceActivity)).format(
                        "HH:mm:ss DD-MM-YYYY"
                      )
                    : ""}
                </a>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card className="border border-primary shadow rounded">
        <CardHeader className="bg-transparent border border-primary border-start-0 border-end-0 border-top-0">
          <h4 className="mt-2 mb-2 text-primary">
            Mobile Aplication Info{" "}
          </h4>
        </CardHeader>
        <CardBody>
          <Table
            className="table table-sm"
            data={data}
            tableInfo={tableInfo} 
           setTableInfo={setTableInfo}
            responsive
            bordered
            loadData={loadData}
            isLoading={isLoading}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Dashboard;
