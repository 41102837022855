import React from "react";

export interface DropDownProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const DropDown: React.FC<DropDownProps> = (props: DropDownProps) => {
  let className = "dropdown" + (props.className ? " " + props.className : "");
  return (
    <div className={className} style={props.style}>
      {props.children}
    </div>
  );
};

export default DropDown;
