import React from "react";

export interface ProgressProps {
  className?: string;
  style?: React.CSSProperties;
  value?: number;
  color?: string;
  children?: React.ReactNode;
}

const Progress: React.FC<ProgressProps> = (props: ProgressProps) => {
  const color = "bg-" + (props.color ? props.color : "primary");
  const style = {
    ...props.style,
    width: props.value + "%",
  };
  return (
    <div
      className={"progress" + (props.className ? " " + props.className : "")}
      style={props.style}
    >
      <div
        className={"progress-bar" + " " + color}
        role="progressbar"
        style={style}
        aria-valuenow={props.value}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Progress;
