import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "./Icon";
import "./SideNavLink.css";

export interface SideNavLinkProps {
  to?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  image?: string;
  sidebarOpen?: boolean;
  tooltip?: string;
}

const SideNavLink: React.FC<SideNavLinkProps> = (props: SideNavLinkProps) => {
  return (
    <li className="side-nav-link">
        <NavLink
        className={({ isActive }) =>
          "d-block side-nav-link-container " + props.className + (isActive ? " active" : "")
        }
        style={{ textDecoration: "none" }}
        to={props.to ?? ""}>
        <div className="d-flex flex-row align-items-center ps-2" style={{ minHeight: '35px' }}>
          <Icon
            className="side-nav-link-icon me-2"
            name={props.image}
          />
          <span>{props.children}</span>
          {props.tooltip && !props.sidebarOpen && (
            <span className="tooltip-text">{props.tooltip}</span>
          )}
          {props.tooltip && props.sidebarOpen && (
            <span className="tooltip-text-open">{props.tooltip}</span>
          )}
        </div>
      </NavLink>
    </li>
  );
};

export default SideNavLink;
